import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentTags(state: State) {
    return state.tags;
  },
  currentTagInfo(state: State) {
    return state.tagInfo;
  },
};
