import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
import { convertEstateInfo } from "@/core/helpers/extension";
export default <ActionTree<State, any>>{
  [Actions.GET_DIRECTION](store) {
    return ApiService.get(`/admin/estate/direction`)
      .then(({ data }) => {
        store.commit(Mutations.SET_DIRECTION, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_ESTATES](store, payload) {
    return ApiService.query(`/admin/estates`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATES, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_INFO](store, payload) {
    return ApiService.get(`/admin/estate/${payload}`)
      .then(({ data }) => {
        store.commit(
          Mutations.SET_ESTATE_INFO,
          convertEstateInfo(data.resultObj)
        );
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_DIRECTION_SELECT](store) {
    return ApiService.get(`/admin/estate/direction/select`)
      .then(({ data }) => {
        store.commit(Mutations.SET_DIRECTION_SELECT, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.ADD_ESTATE](store, payload) {
    return ApiService.post(`/admin/estate`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_ESTATE](store, payload) {
    return ApiService.put(`/admin/estate/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.BROWSE_ESTATE](store, payload) {
    return ApiService.put(`/admin/estate/${payload.id}/browser`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_ESTATE_DETAILS](store, payload) {
    return ApiService.get(`/admin/estate/${payload}/details`)
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_DETAILS, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_TYPES](store, payload) {
    return ApiService.get(`/admin/estate/${payload.id}/type/${payload.type}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_TYPES, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_ESTATE_PUBLISHED](store, payload) {
    return ApiService.patchData(`/admin/estate/${payload}/published`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_ESTATE_COMMENT](store, payload) {
    return ApiService.patchData(`/admin/estate/${payload}/state-comment`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_ESTATE_PROJECT](store, payload) {
    return ApiService.patchData(`/admin/estate/${payload}/project`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_ESTATE_ROUTE](store, payload) {
    return ApiService.patchData(`/admin/estate/${payload}/route`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_ESTATE_WARNING](store, payload) {
    return ApiService.patchData(`/admin/estate/${payload}/warning`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_ESTATE_APPROVED](store, payload) {
    return ApiService.patchData(`/admin/estate/${payload}/approve`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_ESTATE_VIOLATIVE](store, payload) {
    return ApiService.patch(
      `/admin/estate/${payload.id}/violative`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.RETURN_ESTATE](store, payload) {
    return ApiService.patch(`/admin/estate/${payload.id}/return`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_ESTATE_EXPIRED](store, payload) {
    return ApiService.patch(`/admin/estate/${payload.id}/expired`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },

  [Actions.GET_ESTATE_SEO](store, payload) {
    return ApiService.get(`/admin/estate/${payload}/seo`)
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_SEO, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.EDIT_ESTATE_SEO](store, payload) {
    return ApiService.patch(`/admin/estate/${payload.id}/seo`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_TAGS](store, payload) {
    return ApiService.query(`/admin/estate/tags`, {
      params: payload,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_TAGS, data.resultObj);
        console.log(data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_TAGS_SEARCH](store, payload) {
    return ApiService.query(`/admin/estate/tags`, {
      params: payload,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_TAGS_SEARCH, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.EDIT_ESTATE_TAG](store, payload) {
    return ApiService.patch(`/admin/estate/${payload.id}/tags`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_COMMENTS](store, payload) {
    return ApiService.query(`/admin/estate/comments`, {
      params: payload,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_COMMENTS, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_COMMENT_INFO](store, payload) {
    return ApiService.query(`/admin/estate/${payload.id}/comments`, {
      params: payload.data,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_COMMENT_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_COMMENT_CHILD](store, payload) {
    return ApiService.get(`/admin/estate/comment/${payload}/reply`)
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_COMMENT_CHILD, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.APPROVED_ESTATE_COMMENT](store, payload) {
    return ApiService.patchData(`/admin/estate/comment/${payload}/approved`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.DELETE_ESTATE_COMMENT](store, payload) {
    return ApiService.delete(`/admin/estate/comment/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.DELETE_ESTATE](store, payload) {
    return ApiService.delete(`/admin/estate/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.DELETE_ESTATE_REPORT](store, payload) {
    return ApiService.delete(`/admin/estate/${payload}/report`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_REPORT](store, payload) {
    return ApiService.get(`/admin/estate/${payload}/reports`)
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_REPORT, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATES_PENDING](store, payload) {
    return ApiService.query(`/admin/estates-pending`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATES, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_INFO_PENDING](store, payload) {
    return ApiService.get(`/admin/estate-pending/${payload}`)
      .then(({ data }) => {
        console.log(data);
        store.commit(
          Mutations.SET_ESTATE_INFO,
          convertEstateInfo(data.resultObj)
        );
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_DETAILS_PENDING](store, payload) {
    return ApiService.get(`/admin/estate-pending/${payload}/details`)
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_DETAILS, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.EDIT_ESTATE_PENDING](store, payload) {
    return ApiService.put(`/admin/estate-pending/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
        store.commit(Mutations.RESET_ESTATE_INFO);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.CANCEL_ESTATE_PENDING](store, payload) {
    return ApiService.patchData(`/admin/estate-pending/${payload}/cancel`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_ESTATE_COMMENT_ALL](store, payload) {
    return ApiService.query(`/admin/estate/comment/all`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_ESTATE_COMMENT_ALL, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
};
