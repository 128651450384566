import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
import { FormInfo, FormSort } from "@/interfaces/IForms";

export default <MutationTree<State>>{
  [Mutations.SET_FORMS](state, payload) {
    state.forms = payload;
  },
  [Mutations.SET_FORM_INFO](state, payload) {
    state.formInfo = payload;
  },
  [Mutations.SET_FORM_SELECT](state, payload) {
    state.formParents = payload;
  },
  [Mutations.SET_FORM_SORT](state, payload) {
    state.formSort = payload;
  },
  [Mutations.RESET_FORM_INFO](state) {
    state.formInfo = {} as FormInfo;
  },
  [Mutations.RESET_FORM_SORT](state) {
    state.formSort = {} as FormSort[];
  },
};
