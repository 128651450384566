import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
export default <ActionTree<State, any>>{
  [Actions.GET_COMMENTS](store, payload) {
    return ApiService.query(`/admin/comments`, {
      params: payload,
    })
      .then(({ data }) => {
        console.log(data);
        store.commit(Mutations.SET_COMMENTS, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_COMMENT_CHILD](store, payload) {
    return ApiService.get(`/admin/comment/${payload.id}/reply/${payload.type}`)
      .then(({ data }) => {
        console.log(data.resultObj);
        store.commit(Mutations.SET_COMMENT_CHILD, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.APPROVED_COMMENT](store, payload) {
    return ApiService.patchData(`/admin/comment/${payload}/approved`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.DELETE_COMMENT](store, payload) {
    return ApiService.delete(`/admin/comment/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_COMMENT_NEWS](store, payload) {
    return ApiService.query(`/admin/comment/news`, {
      params: payload,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_COMMENT_TYPE, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_COMMENT_TRAVEL](store, payload) {
    return ApiService.query(`/admin/comment/travel`, {
      params: payload,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_COMMENT_TYPE, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_COMMENT_ESTATE](store, payload) {
    return ApiService.query(`/admin/comment/estates`, {
      params: payload,
    })
      .then(({ data }) => {
        console.log(data.resultObj);
        store.commit(Mutations.SET_COMMENT_TYPE, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_COMMENT_INFO](store, payload) {
    return ApiService.query(`/admin/comment/type/${payload.id}`, {
      params: payload.data,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_COMMENT_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
};
