import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <ActionTree<State, any>>{
  [Actions.GET_TAG_TYPES](store) {
    return ApiService.get("/admin/Tag/select").then(({ data }) => {
      store.commit(Mutations.SET_TAG_TYPE, data.resultObj);
    });
  },
  [Actions.GET_TAGS](store, payload) {
    return ApiService.query(`/admin/tags`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_TAGS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_TAG_INFO](store, payload) {
    return ApiService.get(`/admin/Tag/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_TAG_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.ADD_TAG](store, payload) {
    return ApiService.post(`/admin/Tag`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.ADD_TAG_NEW](store, payload) {
    return ApiService.post(`/admin/Tag/create/upload`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_TAG](store, payload) {
    return ApiService.put(`/admin/Tag/${payload.id}`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_TAG_NEW](store, payload) {
    return ApiService.put(`/admin/Tag/${payload.id}/upload`, payload.formData)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.DELETE_TAG](store, payload) {
    return ApiService.delete(`/admin/Tag/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
};
