import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <MutationTree<State>>{
  [Mutations.SET_PRICE_POSTS](state, payload) {
    state.pricePosts = payload;
  },
  [Mutations.SET_PRICE_POST_INFO](state, payload) {
    state.pricePostInfo = payload;
  },
  [Mutations.SET_PRICE_POST_DURATION](state, payload) {
    state.pricePostDurations = payload;
  },
  [Mutations.SET_PRICE_POST_PROVINCE](state, payload) {
    state.pricePostProvinces = payload;
  },
  [Mutations.SET_PRICE_POST_DISTRICT](state, payload) {
    state.pricePostDistricts = payload;
  },
};
