import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
export default <ActionTree<State, any>>{
  [Actions.GET_SITEMAPS](store, payload) {
    return ApiService.get(`/admin/sitemaps/${payload}`)
      .then(({ data }) => {
        console.log(data);
        store.commit(Mutations.SET_SITEMAPS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },

  [Actions.GET_SITEMAP_DETAILS](store, payload) {
    console.log(payload);
    return ApiService.query(`/admin/sitemap/details`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_SITEMAP_DETAILS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_SITEMAP_ESTATE](store, payload) {
    return ApiService.get(`/admin/sitemap/estate`, payload)
      .then(({ data }) => {
        store.commit(Mutations.SET_SITEMAP_ESTATE, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_SITEMAP_TRAVEL](store, payload) {
    return ApiService.get(`/admin/sitemap/travel`, payload)
      .then(({ data }) => {
        store.commit(Mutations.SET_SITEMAP_TRAVEL, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_SITEMAP_NEWS](store, payload) {
    return ApiService.get(`/admin/sitemap/news`, payload)
      .then(({ data }) => {
        store.commit(Mutations.SET_SITEMAP_NEWS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.ADD_SITEMAP](store, payload) {
    return ApiService.post(`/admin/sitemap/create`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.DELETE_SITEMAP_INFO](store, payload) {
    return ApiService.post(`/admin/sitemap/delete-link`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.DELETE_SITEMAP](store, payload) {
    return ApiService.post(`/admin/sitemap/delete`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPLOAD_SITEMAP](store, payload) {
    return ApiService.post(`/admin/sitemap/upload`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_SITEMAP](store, payload) {
    return ApiService.put(`/admin/sitemap`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_SITEMAP_NAME](store, payload) {
    store.commit(Mutations.SET_SITEMAP_NAME, payload);
  },
};
