import { CommitmentPackQuery } from "@/interfaces/ICommitmentPack";

interface CommitmentPack {
  commitmentPackQuery: CommitmentPackQuery;
}

export class State implements CommitmentPack {
  commitmentPackQuery = {
    page: 1,
    pageSize: 30,
    name: "",
  };
}
