import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
import { convertEstateInfo } from "@/core/helpers/extension";
export default <ActionTree<State, any>>{
  [Actions.GET_TRAVELS](store, payload) {
    return ApiService.query(`/admin/travels`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_TRAVELS, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_TRAVEL_INFO](store, payload) {
    return ApiService.get(`/admin/travel/${payload}`)
      .then(({ data }) => {
        store.commit(
          Mutations.SET_TRAVEL_INFO,
          convertEstateInfo(data.resultObj)
        );
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.ADD_TRAVEL](store, payload) {
    return ApiService.post(`/admin/travel`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_TRAVEL](store, payload) {
    return ApiService.put(`/admin/travel/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },

  [Actions.GET_TRAVEL_TYPES](store, payload) {
    return ApiService.get(`/admin/travel/${payload.id}/type/${payload.type}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_TRAVEL_TYPES, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_TRAVEL_PUBLISHED](store, payload) {
    return ApiService.patchData(`/admin/travel/${payload}/published`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_TRAVEL_COMMENT](store, payload) {
    return ApiService.patchData(`/admin/travel/${payload}/state-comment`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },

  [Actions.GET_TRAVEL_SEO](store, payload) {
    return ApiService.get(`/admin/travel/${payload}/seo`)
      .then(({ data }) => {
        console.log(data.resultObj);
        store.commit(Mutations.SET_TRAVEL_SEO, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.EDIT_TRAVEL_SEO](store, payload) {
    console.log(payload);
    return ApiService.patch(`/admin/travel/seo`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_TRAVEL_TAGS](store, payload) {
    return ApiService.query(`/admin/travel/tags`, {
      params: payload,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_TRAVEL_TAGS, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_TRAVEL_TAGS_SEARCH](store, payload) {
    return ApiService.query(`/admin/travel/tags`, {
      params: payload,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_TRAVEL_TAGS_SEARCH, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.EDIT_TRAVEL_TAG](store, payload) {
    return ApiService.patch(`/admin/travel/${payload.id}/tags`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_TRAVEL_EXPIRED](store, payload) {
    return ApiService.patch(`/admin/travel/${payload.id}/expired`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.DELETE_TRAVEL](store, payload) {
    return ApiService.delete(`/admin/travel/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_TRAVEL_PROJECT](store, payload) {
    return ApiService.patchData(`/admin/travel/${payload}/project`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_TRAVEL_ROUTE](store, payload) {
    return ApiService.patchData(`/admin/travel/${payload}/route`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.UPDATE_TRAVEL_VIOLATIVE](store, payload) {
    return ApiService.patch(
      `/admin/travel/${payload.id}/violative`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_TRAVEL_REPORT](store, payload) {
    return ApiService.get(`/admin/travel/${payload}/reports`)
      .then(({ data }) => {
        store.commit(Mutations.SET_TRAVEL_REPORT, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.DELETE_TRAVEL_REPORT](store, payload) {
    return ApiService.delete(`/admin/travel/${payload}/report`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
};
