import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentReportAccount(state: State) {
    return state.reportAccount;
  },
  currentReportPosts(state: State) {
    return state.reportPosts;
  },
  currentReportPostsVip(state: State) {
    return state.reportPostVip;
  },
  currentReportPostsInfo(state: State) {
    return state.reportPostsInfo;
  },
  currentReportPostsVipInfo(state: State) {
    return state.reportPostsVipInfo;
  },
  currentReportTraffic(state: State) {
    return state.reportTraffic;
  },
  currentReportOrder(state: State) {
    return state.reportOrder;
  },
  currentReportProduct(state: State) {
    return state.reportProduct;
  },
  currentReportSeller(state: State) {
    return state.reportSeller;
  },
  currentReportTransaction(state: State) {
    return state.reportTransaction;
  },
  currentReportExcel(state: State) {
    return state.reportExcel;
  },
};
