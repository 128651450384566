import {
  IUIForm,
  UIFormInfo,
  UIFormCreate,
  UIFormSelect,
} from "@/interfaces/IUIForm";
export class State {
  uiforms = {} as IUIForm;
  uiformInfo = {} as UIFormInfo;
  uiformCreate = {} as UIFormCreate[];
  uiformSelect = {} as UIFormSelect[];
}
