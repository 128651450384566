import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
export default <ActionTree<State, any>>{
  [Actions.GET_UISECTIONS](store, payload) {
    return ApiService.query(`/admin/sections`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_UISECTIONS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_UISECTION_INFO](store, payload) {
    return ApiService.get(`/admin/section/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_UISECTION_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_UISECTION_PAGE](store) {
    return ApiService.get(`/admin/section/page`)
      .then(({ data }) => {
        store.commit(Mutations.SET_UISECTION_PAGE, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_UISECTION_OF_PAGE](store, payload) {
    return ApiService.get(`/admin/section/page/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_UISECTION_OF_PAGE, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_UISECTION](store, payload) {
    return ApiService.put(`/admin/section/${payload.id}`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
        store.commit(Mutations.RESET_UISECTION_INFO);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.ADD_UISECTION](store, payload) {
    return ApiService.post(`/admin/section`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
        store.commit(Mutations.RESET_UISECTION_INFO);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_UISECTION_IMAGE](store, payload) {
    return ApiService.get(`/admin/section/image/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.RESET_ERROR);
        store.commit(Mutations.SET_UISECTION_IMAGE, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_UISECTION_IMAGE](store, payload) {
    return ApiService.get(`/admin/section/image/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.RESET_ERROR);
        store.commit(Mutations.SET_UISECTION_IMAGE, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_UISECTION_IMAGE](store, payload) {
    return ApiService.patch(`/admin/section/image/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
        store.commit(Mutations.RESET_UISECTION_IMAGE);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.DELETE_SECTION](store, payload) {
    return ApiService.delete(`/admin/section/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_UISECTION_SORT](store, payload) {
    return ApiService.get(`/admin/section/sort/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_UISECTION_SORT, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_UISECTION_SORT](store, payload) {
    return ApiService.patch(`/admin/section/sort`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_UISECTION_SORT](store, payload) {
    store.commit(Mutations.SET_UISECTION_SORT, payload);
  },
};
