import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentForms(state: State) {
    return state.forms;
  },
  currentFormInfo(state: State) {
    return state.formInfo;
  },
  currentFormSelect(state: State) {
    return state.formParents;
  },
  currentFormSort(state: State) {
    return state.formSort;
  },
};
