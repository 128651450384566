import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentUISections(state: State) {
    return state.uiSections;
  },
  currentUISectionInfo(state: State) {
    return state.uiSectionInfo;
  },
  currentUISectionPage(state: State) {
    return state.uiSetionPage;
  },
  currentUISectionOfPage(state: State) {
    return state.uiSectionOfPage;
  },
  currentUISectionImage(state: State) {
    return state.uiSectionImage;
  },
  currentUISectionSort(state: State) {
    return state.uiSectionSort;
  },
};
