import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <MutationTree<State>>{
  [Mutations.SET_NOTIFICATIONS](state, payload) {
    state.notifications = payload;
  },
  [Mutations.SET_NOTIFICATION_INFO](state, payload) {
    state.notificationInfo = payload;
  },
};
