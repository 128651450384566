import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <ActionTree<State, any>>{
  [Actions.GET_TOURGUIDES](store, payload) {
    return ApiService.query(`/admin/tourguides`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_TOURGUIDES, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_TOURGUIDE_INFO](store, payload) {
    return ApiService.get(`/admin/tourguide/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_TOURGUIDE_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.RESET_TOURGUIDE_INOFO](store) {
    store.commit(Mutations.SET_TOURGUIDE_INFO, {});
  },
  [Actions.GET_TOURGUIDE_PENDING](store, payload) {
    return ApiService.get(`/admin/tourguide/${payload}/pending`)
      .then(({ data }) => {
        store.commit(Mutations.SET_TOURGUIDE_PENDING, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_TOURGUIDE](store, payload) {
    return ApiService.patch(`/admin/tourGuide/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },

  [Actions.APPROVE_TOURGUIDE_PENDING](store, payload) {
    return ApiService.patch(
      `/admin/tourGuide/approved-pending/${payload.id}`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.REFUSE_TOURGUIDE_PENDING](store, payload) {
    return ApiService.patchData(`/admin/tourGuide/${payload}/refuse`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_DISCOUNT_TOURGUIDE](store, payload) {
    return ApiService.patch(
      `/admin/tourGuide/${payload.id}/discount`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
};
