import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <MutationTree<State>>{
  [Mutations.SET_BANNERS](state, payload) {
    state.banners = payload;
  },
  [Mutations.SET_BANNER_INFO](state, payload) {
    state.bannerInfo = payload;
  },
};
