const ID_TOKEN_KEY = "id_token" as string;
const ROLE_USER = "role-user" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};
/**
 * @description get roles form localStorage
 */
export const getRoles = (): any | null => {
  return window.localStorage.getItem(ROLE_USER);
};

/**
 * @description save roles into localStorage
 * @param roles: string
 */
export const saveRoles = (roles: any): void => {
  window.localStorage.setItem(ROLE_USER, roles);
};

/**
 * @description remove roles form localStorage
 */
export const destroyRoles = (): void => {
  window.localStorage.removeItem(ROLE_USER);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getRoles,
  saveRoles,
  destroyRoles,
};
