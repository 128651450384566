import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
import { convertPosition } from "@/core/helpers/extension";
export default <ActionTree<State, any>>{
  [Actions.GET_POSITION_PROVINCE](store, payload) {
    return ApiService.get(`/admin/position/province/${payload}`)
      .then(({ data }) => {
        console.log(data);
        store.commit(Mutations.SET_POSITION, convertPosition(data.resultObj));
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_POSITION_DISTRICT](store, payload) {
    return ApiService.get(`/admin/position/district/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_POSITION, convertPosition(data.resultObj));
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_POSITION_WARD](store, payload) {
    return ApiService.get(`/admin/position/ward/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_POSITION, convertPosition(data.resultObj));
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },

  [Actions.EDIT_POSITION_PROVINCE](store, payload) {
    return ApiService.patch(
      `/admin/position/province/${payload.id}`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_POSITION);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_POSITION_DISTRICT](store, payload) {
    return ApiService.patch(
      `/admin/position/district/${payload.id}`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_POSITION);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_POSITION_WARD](store, payload) {
    return ApiService.patch(`/admin/position/ward/${payload.id}`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_POSITION);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
};
