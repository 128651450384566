import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { IProvince, ProvinceSelect } from "@/interfaces/IProvince";
import { convertPosition } from "@/core/helpers/extension";
@Module
export default class ProvinceModule extends VuexModule {
  provinces = {} as IProvince[];
  provinceInfo = {} as IProvince;
  provinceSelect = {} as ProvinceSelect[];
  provinceLocation = {};
  provinceInfoType = {};
  /**
   * Get provinces
   * @returns IProvince[]
   */
  get currentProvinces(): IProvince[] {
    return this.provinces;
  }
  /**
   * Get province Select
   * @returns ProvinceSelect[]
   */
  get getProvinceSeleted(): ProvinceSelect[] {
    return this.provinceSelect;
  }
  /**
   * Get province Info
   * @returns IProvince
   */
  get currentProvinceInfo(): IProvince {
    return this.provinceInfo;
  }
  /**
   * Get province location branch
   */
  get currentProvinceLocation() {
    return this.provinceLocation;
  }
  /**
   * Get province location branch
   */
  get currentProvinceInfoType() {
    return this.provinceInfoType;
  }

  @Mutation
  [Mutations.SET_PROVINCES](payload): void {
    this.provinces = payload;
  }
  @Mutation
  [Mutations.SET_PROVINCE_SELECT](payload): void {
    this.provinceSelect = payload;
  }
  @Mutation
  [Mutations.SET_PROVINCE_INFO](payload): void {
    this.provinceInfo = payload;
  }
  @Mutation
  [Mutations.SET_PROVINCE_INFOTYPE](payload): void {
    this.provinceInfoType = payload;
  }
  @Mutation
  [Mutations.SET_POSITION_BRANCH_PROVINCE](payload): void {
    this.provinceLocation = payload;
  }
  @Mutation
  [Mutations.RESET_PROVINCE](): void {
    this.provinces = {} as IProvince[];
    this.provinceInfo = {} as IProvince;
    this.provinceSelect = {} as ProvinceSelect[];
    this.provinceInfoType = {};
  }

  @Action
  [Actions.GET_PROVINCES](payload) {
    // this.context.commit(Mutations.RESET_PROVINCE);
    return ApiService.query(`/admin/provinces`, { params: payload })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCES, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }
  @Action
  [Actions.GET_POSITION_BRANCH_PROVINCE](payload) {
    return ApiService.query(`/admin/province/location/branch`, {
      params: payload,
    })
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_POSITION_BRANCH_PROVINCE,
          convertPosition(data.resultObj)
        );
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }
  @Action
  [Actions.GET_PROVINCE_INFO](payload) {
    return ApiService.get(`/admin/province/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCE_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_PROVINCE_INFO_BY_TYPE](payload) {
    return ApiService.get(`/admin/province/info/${payload.id}/${payload.type}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCE_INFOTYPE, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.UPDATE_PROVINCE_INFO_BY_ID](payload) {
    return ApiService.patch(`/admin/province/info/${payload.id}`, payload.data)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCE_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_PROVINCE_SELECT](payload) {
    return ApiService.get(`/admin/province/select/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCE_SELECT, data.resultObj);
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.ADD_PROVINCE](payload) {
    return ApiService.post(`/admin/province`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
        //this.context.commit(Mutations.RESET_COUNTRY_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.EDIT_PROVINCE](payload) {
    return ApiService.put(`/admin/province/${payload.id}`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
        //this.context.commit(Mutations.RESET_COUNTRY_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.EDIT_POSITION_BRANCH_PROVINCE](payload) {
    return ApiService.patch(`/admin/province/location/branch`, payload.data)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.DELETE_PROVINCE](payload) {
    return ApiService.delete(`/admin/province/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
        // this.context.commit(Mutations.RESET_PROVINCE);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.RESET_POSITION]() {
    this.context.commit(Mutations.SET_POSITION, {});
    this.context.commit(Mutations.SET_POSITION_BRANCH_PROVINCE, {});
  }
}
