import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { ActionTree } from "vuex";
import { Actions, Mutations, EndPointAPI } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <ActionTree<State, any>>{
  [Actions.LOGIN](store, credentials) {
    return ApiService.post("/admin/Account/authencation", credentials)
      .then(({ data }) => {
        store.commit(Mutations.SET_AUTH, data.resultObj);
      })
      .catch(({ response }) => {
        const errorResponse = response.data.validationErrors;
        store.commit(Mutations.SET_ERROR, errorResponse);
      });
  },

  [Actions.REGISTER](store, credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        store.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.errors);
      });
  },

  // [Actions.FORGOT_PASSWORD](store, payload) {
  //   return ApiService.post("forgot_password", payload)
  //     .then(() => {
  //       store.commit(Mutations.SET_ERROR, {});
  //     })
  //     .catch(({ response }) => {
  //       store.commit(Mutations.SET_ERROR, response.data.errors);
  //     });
  // }

  [Actions.LOGOUT](store) {
    store.commit(Mutations.PURGE_AUTH);
  },

  [Actions.VERIFY_AUTH](store) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(EndPointAPI.GET_USER_INFO)
        .then(({ data }) => {
          store.commit(Mutations.SET_USER, data.resultObj);
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data.message);
          store.commit(Mutations.PURGE_AUTH);
        });
    } else {
      store.commit(Mutations.PURGE_AUTH);
    }
  },
};
