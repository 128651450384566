import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <MutationTree<State>>{
  [Mutations.SET_TRAVELS](state, payload) {
    state.travels = payload;
  },
  [Mutations.SET_TRAVEL_INFO](state, payload) {
    state.travelInfo = payload;
  },
  [Mutations.SET_TRAVEL_DETAILS](state, payload) {
    state.travelDetails = payload;
  },
  [Mutations.SET_TRAVEL_TYPES](state, payload) {
    state.travelTypes = payload;
  },
  [Mutations.SET_TRAVEL_SEO](state, payload) {
    state.travelSEO = payload;
  },
  [Mutations.SET_TRAVEL_TAGS](state, payload) {
    state.travelTags = payload;
  },
  [Mutations.SET_TRAVEL_TAGS_SEARCH](state, payload) {
    state.travelTagsSearch = payload;
  },
  [Mutations.SET_TRAVEL_REPORT](state, payload) {
    state.travelReports = payload;
  },
};
