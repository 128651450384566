import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentSiteMaps(state: State) {
    return state.sitemaps;
  },
  currentSiteMapDetails(state: State) {
    return state.sitemapDetails;
  },
  currentSiteMapEstate(state: State) {
    return state.sitemapEstate;
  },
  currentSiteMapTravel(state: State) {
    return state.sitemapTravel;
  },
  currentSiteMapNews(state: State) {
    return state.siteMapNews;
  },
  currentSiteMapName(state: State) {
    return state.sitemapName;
  },
};
