import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentDirections(state: State) {
    return state.directions;
  },
  currentDirectionSelect(state: State) {
    return state.directionSelect;
  },
  currentEstates(state: State) {
    return state.estates;
  },
  currentEstateInfo(state: State) {
    return state.estateInfo;
  },
  currentEstateDetails(state: State) {
    return state.estateDetails;
  },
  currentEstateTypes(state: State) {
    return state.estateTypes;
  },
  currentEstateSEO(state: State) {
    return state.estateSEO;
  },
  currentEstateTags(state: State) {
    return state.estateTags;
  },
  currentEstateTagsSearch(state: State) {
    return state.estateTagsSearch;
  },
  currentEstateComments(state: State) {
    return state.estateComments;
  },
  currentEstateCommentInfo(state: State) {
    return state.estateCommentInfo;
  },
  currentEstateCommentChild(state: State) {
    return state.estateCommentChild;
  },
  currentEstateReports(state: State) {
    return state.estateReports;
  },
  currentEstateCommnetAll(state: State) {
    return state.estateCommentAlls;
  },
};
