import { ArticleQuery, ArticleOrder } from "@/interfaces/IArticle";

interface Article {
  articleQuery: ArticleQuery;
}

export class State implements Article {
  articleQuery = {
    page: 1,
    pageSize: 30,
    order: ArticleOrder.DESC,
    articleCategoryId: null as any,
    tagId: null as any,
    published: null as any,
    name: "",
    tagName: "",
  };
}
