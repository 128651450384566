import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ValidationError } from "../../interfaces/IApiResult";
import { ConfigWebsite } from "../../interfaces/Config";

@Module
export default class SettingModule extends VuexModule {
  errors = {} as ValidationError[];
  setting = {} as ConfigWebsite;
  /**
   * Get settingWebsite
   * @returns ConfigWebsite
   */
  get currentSetting(): ConfigWebsite {
    return this.setting;
  }

  @Mutation
  [Mutations.SET_SETTING](payload): void {
    this.setting = payload;
  }
  @Action
  [Actions.GET_SETTING]() {
    return ApiService.get(`/admin/SettingAdmin`)
      .then(({ data }) => {
        console.log(data.resultObj);
        this.context.commit(Mutations.SET_SETTING, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.EDIT_SETTING](payload) {
    return ApiService.put(`/admin/SettingAdmin`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
}
