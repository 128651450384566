import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
import { TypeInfo, TypeIcon } from "@/interfaces/ITypes";

export default <MutationTree<State>>{
  [Mutations.SET_TYPES](state, payload) {
    state.types = payload;
  },
  [Mutations.SET_TYPE_INFO](state, payload) {
    state.typeInfo = payload;
  },
  [Mutations.SET_TYPE_SELECT](state, payload) {
    state.typeParents = payload;
  },
  [Mutations.SET_TYPE_ICON](state, payload) {
    state.typeIcon = payload;
  },
  [Mutations.SET_TYPE_SORT](state, payload) {
    state.typeSort = payload;
  },
  [Mutations.RESET_TYPE_INFO](state) {
    state.typeInfo = {} as TypeInfo;
  },
  [Mutations.RESET_TYPE_ICON](state) {
    state.typeIcon = {} as TypeIcon;
  },
};
