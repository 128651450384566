import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentTravels(state: State) {
    return state.travels;
  },
  currentTravelInfo(state: State) {
    return state.travelInfo;
  },
  currentTravelDetails(state: State) {
    return state.travelDetails;
  },
  currentTravelTypes(state: State) {
    return state.travelTypes;
  },
  currentTravelSEO(state: State) {
    return state.travelSEO;
  },
  currentTravelTags(state: State) {
    return state.travelTags;
  },
  currentTravelTagsSearch(state: State) {
    return state.travelTagsSearch;
  },
  currentTravelReports(state: State) {
    return state.travelReports;
  },
};
