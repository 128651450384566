import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <MutationTree<State>>{
  [Mutations.SET_SITEMAPS](state, payload) {
    state.sitemaps = payload;
  },
  [Mutations.SET_SITEMAP_DETAILS](state, payload) {
    state.sitemapDetails = payload;
  },
  [Mutations.SET_SITEMAP_ESTATE](state, payload) {
    state.sitemapEstate = payload;
  },
  [Mutations.SET_SITEMAP_TRAVEL](state, payload) {
    state.sitemapTravel = payload;
  },
  [Mutations.SET_SITEMAP_NEWS](state, payload) {
    state.siteMapNews = payload;
  },
  [Mutations.SET_SITEMAP_NAME](state, payload) {
    state.sitemapName = payload;
  },
};
