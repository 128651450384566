import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { Permission } from "@/core/config/RulePermission";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          modules: {
            dashboardModule: () => require("@/store/modules/DashboardModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            reportModule: () => require("@/store/modules/ReportModule"),
          },
          roles: [Permission.PERMISSION_REPORTNEW_VIEW],
        },
      },
      {
        path: "/traffic",
        name: "traffic",
        component: () => import("@/views/Traffic.vue"),
        meta: {
          modules: {
            settingModule: () => require("@/store/modules/SettingModule"),
            reportModule: () => require("@/store/modules/ReportModule"),
          },
          roles: [
            Permission.PERMISSION_REPORTNEW_VIEW,
            Permission.PERMISSION_REPORTNEW_TRAFFIC,
          ],
        },
      },

      //Permission route
      {
        path: "/roles",
        name: "role-user",
        component: () => import("@/views/permission/RoleUser.vue"),
        meta: {
          modules: {
            roleModule: () => require("@/store/modules/RoleModule"),
          },
          roles: [Permission.PERMISSION_ROLE_VIEW],
        },
      },
      {
        path: "/role/:id/permission",
        name: "role-permission",
        component: () => import("@/views/permission/Permission.vue"),
        meta: {
          modules: {
            roleModule: () => require("@/store/modules/RoleModule"),
          },
          roles: [Permission.PERMISSION_ROLE_EDIT],
        },
      },
      {
        path: "/users/admin",
        name: "user-admin",
        component: () => import("@/views/permission/UserAdmin.vue"),
        meta: {
          modules: {
            roleModule: () => require("@/store/modules/RoleModule"),
          },
          roles: [Permission.PERMISSION_ROLE_VIEW],
        },
      },
      {
        path: "/users/admin/create",
        name: "user-admin-create",
        component: () => import("@/views/permission/AddUserAdmin.vue"),
        meta: {
          modules: {
            roleModule: () => require("@/store/modules/RoleModule"),
          },
          roles: [Permission.PERMISSION_ROLE_CREATE],
        },
      },
      {
        path: "/users/admin/:id",
        name: "user-admin-edit",
        component: () => import("@/views/permission/EditUserAdmin.vue"),
        meta: {
          modules: {
            roleModule: () => require("@/store/modules/RoleModule"),
          },
          roles: [Permission.PERMISSION_ROLE_EDIT],
        },
      },
      {
        path: "/config/website",
        name: "config-website",
        component: () => import("@/views/setting/ConfigWebsite.vue"),
        meta: {
          modules: {
            settingModule: () => require("@/store/modules/SettingModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/menu",
        name: "menu-website",
        component: () => import("@/views/setting/Menu.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/menu/add",
        name: "menu-website-add",
        component: () => import("@/views/setting/AddMenu.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/menu/edit/:id",
        name: "menu-website-edit",
        component: () => import("@/views/setting/EditMenu.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/config/menu-top",
        name: "menu-top-website",
        component: () => import("@/views/setting/MenuTop.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/menu-top/add",
        name: "menu-top-website-add",
        component: () => import("@/views/setting/AddMenuTop.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/menu-top/edit/:id",
        name: "menu-top-website-edit",
        component: () => import("@/views/setting/EditMenuTop.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/config/menu-estate",
        name: "menu-estate-website",
        component: () => import("@/views/setting/MenuEstate.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/menu-estate/add",
        name: "menu-estate-website-add",
        component: () => import("@/views/setting/AddMenuEstate.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/menu-estate/edit/:id",
        name: "menu-estate-website-edit",
        component: () => import("@/views/setting/EditMenuEstate.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/config/menu-travel",
        name: "menu-travel-website",
        component: () => import("@/views/setting/MenuTravel.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/menu-travel/add",
        name: "menu-travel-website-add",
        component: () => import("@/views/setting/AddMenuTravel.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/menu-travel/edit/:id",
        name: "menu-travel-website-edit",
        component: () => import("@/views/setting/EditMenuTravel.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/config/menu-estate-location",
        name: "menu-estate-location-website",
        component: () => import("@/views/setting/MenuEstateLocation.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/menu-estate-location/add",
        name: "menu-estate-location-website-add",
        component: () => import("@/views/setting/AddMenuEstateLocation.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/menu-estate-location/edit/:id",
        name: "menu-estate-location-website-edit",
        component: () => import("@/views/setting/EditMenuEstateLocation.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/config/menu-travel-location",
        name: "menu-travel-location-website",
        component: () => import("@/views/setting/MenuTravelLocation.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/menu-travel-location/add",
        name: "menu-travel-location-website-add",
        component: () => import("@/views/setting/AddMenuTravelLocation.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/menu-travel-location/edit/:id",
        name: "menu-travel-location-website-edit",
        component: () => import("@/views/setting/EditMenuTravelLocation.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },

      {
        path: "/config/menu-section",
        name: "menu-section-website",
        component: () => import("@/views/setting/MenuSection.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/menu-section/add",
        name: "menu-section-website-add",
        component: () => import("@/views/setting/AddMenuSection.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/menu-section/edit/:id",
        name: "menu-section-website-edit",
        component: () => import("@/views/setting/EditMenuSection.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/config/menu-profile",
        name: "menu-profile-website",
        component: () => import("@/views/setting/MenuProfile.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/menu-profile/add",
        name: "menu-profile-website-add",
        component: () => import("@/views/setting/AddMenuProfile.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/menu-profile/edit/:id",
        name: "menu-profile-website-edit",
        component: () => import("@/views/setting/EditMenuProfile.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/config/footer",
        name: "footer-website",
        component: () => import("@/views/setting/Footer.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/footer/add",
        name: "footer-website-add",
        component: () => import("@/views/setting/AddFooter.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/footer/edit/:id",
        name: "footer-website-edit",
        component: () => import("@/views/setting/EditFooter.vue"),
        meta: {
          modules: {
            menuModule: () => require("@/store/modules/MenuModule"),
            footerModule: () => require("@/store/modules/FooterModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/config/page/static",
        name: "static-page-website",
        component: () => import("@/views/setting/staticpage/StaticPage.vue"),
        meta: {
          modules: {
            staticPageModule: () => require("@/store/modules/StaticPageModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
      },
      {
        path: "/config/page/static/add",
        name: "static-page-add-website",
        component: () => import("@/views/setting/staticpage/AddStaticPage.vue"),
        meta: {
          modules: {
            staticPageModule: () => require("@/store/modules/StaticPageModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
      },
      {
        path: "/config/page/static/edit/:id",
        name: "static-page-edit-website",
        component: () =>
          import("@/views/setting/staticpage/EditStaticPage.vue"),
        meta: {
          modules: {
            staticPageModule: () => require("@/store/modules/StaticPageModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/config/notification",
        name: "notification-page",
        component: () =>
          import("@/views/setting/notification/NotificationPage.vue"),
        meta: {
          modules: {
            notificationPageModule: () =>
              require("@/store/modules/NotificationModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          //roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/sitemap/main",
        name: "sitemap-main",
        component: () => import("@/views/sitemap/sitemapMain.vue"),
        meta: {
          modules: {
            sitemapPageModule: () => require("@/store/modules/SitemapModule"),
          },
          //roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/sitemap/estate",
        name: "sitemap-estate",
        component: () => import("@/views/sitemap/sitemapEstate.vue"),
        meta: {
          modules: {
            sitemapPageModule: () => require("@/store/modules/SitemapModule"),
          },
          //roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/sitemap/travel",
        name: "sitemap-travel",
        component: () => import("@/views/sitemap/sitemapTravel.vue"),
        meta: {
          modules: {
            sitemapPageModule: () => require("@/store/modules/SitemapModule"),
          },
          //roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/sitemap/news",
        name: "sitemap-news",
        component: () => import("@/views/sitemap/sitemapNews.vue"),
        meta: {
          modules: {
            sitemapPageModule: () => require("@/store/modules/SitemapModule"),
          },
          //roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      {
        path: "/sitemap/details",
        name: "sitemap-details",
        component: () => import("@/views/sitemap/sitemapDetails.vue"),
        meta: {
          modules: {
            sitemapPageModule: () => require("@/store/modules/SitemapModule"),
          },
          //roles: [Permission.PERMISSION_SETTING_EDIT],
        },
      },
      //locations
      {
        path: "/location/country",
        name: "location-country",
        component: () => import("@/views/location/MapCountry.vue"),
        meta: {
          modules: {
            countryModule: () => require("@/store/modules/CountryModule"),
          },
          roles: [Permission.PERMISSION_LOCATION_VIEW],
        },
      },
      {
        path: "/location/province",
        name: "location-province",
        component: () => import("@/views/location/MapProvince.vue"),
        meta: {
          modules: {
            countryModule: () => require("@/store/modules/CountryModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            priceVipModules: () => require("@/store/modules/PriceVipModule"),
            pricePostModules: () => require("@/store/modules/PricePostModule"),
          },
          roles: [Permission.PERMISSION_LOCATION_VIEW],
        },
      },
      {
        path: "/location/province/:id/:type",
        name: "location-province-info",
        component: () => import("@/views/location/ProvinceEstateInfo.vue"),
        meta: {
          modules: {
            countryModule: () => require("@/store/modules/CountryModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            priceVipModules: () => require("@/store/modules/PriceVipModule"),
            pricePostModules: () => require("@/store/modules/PricePostModule"),
          },
          roles: [Permission.PERMISSION_LOCATION_VIEW],
        },
      },
      {
        path: "/location/district",
        name: "location-district",
        component: () => import("@/views/location/MapDistrict.vue"),
        meta: {
          modules: {
            countryModule: () => require("@/store/modules/CountryModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            priceVipModules: () => require("@/store/modules/PriceVipModule"),
            pricePostModules: () => require("@/store/modules/PricePostModule"),
          },
          roles: [Permission.PERMISSION_LOCATION_VIEW],
        },
      },
      {
        path: "/location/ward",
        name: "location-ward",
        component: () => import("@/views/location/MapWard.vue"),
        meta: {
          modules: {
            countryModule: () => require("@/store/modules/CountryModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
          },
          roles: [Permission.PERMISSION_LOCATION_VIEW],
        },
      },
      // article
      {
        path: "/article",
        name: "article",
        component: () => import("@/views/article/article/Article.vue"),
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ArticleModule"),
          },
          roles: [Permission.PERMISSION_ARTICLE_VIEW],
        },
      },
      {
        path: "/article/add",
        name: "article-add",
        component: () => import("@/views/article/article/AddArticle.vue"),
        meta: {
          roles: [Permission.PERMISSION_ARTICLE_CREATE],
        },
      },
      {
        path: "/article/edit/:id",
        name: "article-edit",
        component: () => import("@/views/article/article/EditArticle.vue"),
        meta: {
          roles: [Permission.PERMISSION_ARTICLE_EDIT],
        },
      },
      {
        path: "/article/article-category",
        name: "article-category",
        component: () =>
          import("@/views/article/article_category/ArticleCategory.vue"),
        meta: {
          roles: [Permission.PERMISSION_CATEGORYARTICLE_VIEW],
        },
      },
      {
        path: "/article/article-category/add",
        name: "article-category-add",
        component: () =>
          import("@/views/article/article_category/AddArticleCategory.vue"),
        meta: {
          roles: [Permission.PERMISSION_CATEGORYARTICLE_CREATE],
        },
      },
      {
        path: "/article/article-category/edit/:id",
        name: "article-category-edit",
        component: () =>
          import("@/views/article/article_category/EditArticleCategory.vue"),
        meta: {
          roles: [Permission.PERMISSION_CATEGORYARTICLE_EDIT],
        },
      },
      {
        path: "/article/tag",
        name: "article-tag",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
          },
          roles: [Permission.PERMISSION_TAGS_VIEW],
        },
        component: () => import("@/views/article/tag/Tag.vue"),
      },
      {
        path: "/article/tag/add",
        name: "article-tag-add",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
          },
          roles: [Permission.PERMISSION_TAGS_CREATE],
        },
        component: () => import("@/views/article/tag/AddTag.vue"),
      },
      {
        path: "/article/tag/edit/:id",
        name: "article-tag-edit",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
          },
          roles: [Permission.PERMISSION_TAGS_EDIT],
        },
        component: () => import("@/views/article/tag/EditTag.vue"),
      },
      // user manual
      {
        path: "/user-manual",
        name: "user-manual",
        component: () => import("@/views/user-manual/UserManual.vue"),
        meta: {
          modules: {
            userManualModule: () => require("@/store/modules/UserManualModule"),
          },
          roles: [Permission.PERMISSION_USERMANUAL_VIEW],
        },
      },
      {
        path: "/user-manual/add",
        name: "user-manual-add",
        component: () => import("@/views/user-manual/AddUserManual.vue"),
        meta: {
          roles: [Permission.PERMISSION_USERMANUAL_CREATE],
        },
      },
      {
        path: "/user-manual/edit/:id",
        name: "user-manual-edit",
        component: () => import("@/views/user-manual/EditUserManual.vue"),
        meta: {
          roles: [Permission.PERMISSION_USERMANUAL_EDIT],
        },
      },
      // media
      {
        path: "/media-info",
        name: "media",
        component: () => import("@/views/media/media/MediaHome.vue"),
        meta: {
          roles: [Permission.PERMISSION_MEDIA_VIEW],
        },
      },
      {
        path: "/media-info/:id",
        name: "folder-detail",
        component: () => import("@/views/media/media/FolderDetail.vue"),
        meta: {
          roles: [Permission.PERMISSION_MEDIA_VIEW],
        },
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: "/media-info/trash",
        name: "media-trash",
        component: () => import("@/views/media/trash/Trash.vue"),
        meta: {
          roles: [Permission.PERMISSION_MEDIA_VIEW],
        },
      },
      //seo link
      {
        path: "/seolink",
        name: "seo-link",
        component: () => import("@/views/seo/SeoLink.vue"),
        meta: {
          modules: {
            seolinkModule: () => require("@/store/modules/SeoLinkModule"),
          },
          roles: [Permission.PERMISSION_SEOLINK_VIEW],
        },
      },
      {
        path: "/seolink/add",
        name: "seo-link-add",
        component: () => import("@/views/seo/AddSeoLink.vue"),
        meta: {
          modules: {
            seolinkModule: () => require("@/store/modules/SeoLinkModule"),
          },
          roles: [Permission.PERMISSION_SEOLINK_CREATE],
        },
      },
      {
        path: "/seolink/edit/:id",
        name: "seo-link-edit",
        component: () => import("@/views/seo/EditSeoLink.vue"),
        meta: {
          modules: {
            seolinkModule: () => require("@/store/modules/SeoLinkModule"),
          },
          roles: [Permission.PERMISSION_SEOLINK_EDIT],
        },
      },
      //UI Form
      {
        path: "/ui/form",
        name: "ui-form",
        meta: {
          modules: {
            uiformModule: () => require("@/store/modules/UIFormModule"),
          },
          roles: [Permission.PERMISSION_UI_VIEW],
        },
        component: () => import("@/views/uiform/UIForm.vue"),
      },
      {
        path: "/ui/form/add",
        name: "ui-form-add",
        meta: {
          modules: {
            uiformModule: () => require("@/store/modules/UIFormModule"),
          },
          roles: [Permission.PERMISSION_UI_CREATE],
        },
        component: () => import("@/views/uiform/AddUIForm.vue"),
      },
      {
        path: "/ui/form/edit/:id",
        name: "ui-form-edit",
        meta: {
          modules: {
            uiformModule: () => require("@/store/modules/UIFormModule"),
          },
          roles: [Permission.PERMISSION_UI_EDIT],
        },
        component: () => import("@/views/uiform/EditUIForm.vue"),
      },
      //UI Section
      {
        path: "/ui/section",
        name: "ui-section",
        meta: {
          modules: {
            uiSectionModule: () => require("@/store/modules/UISectionModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_UI_VIEW],
        },
        component: () => import("@/views/uisection/UISection.vue"),
      },
      {
        path: "/ui/section/add",
        name: "ui-section-add",
        meta: {
          modules: {
            uiSectionModule: () => require("@/store/modules/UISectionModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_UI_CREATE],
        },
        component: () => import("@/views/uisection/AddUISection.vue"),
      },
      {
        path: "/ui/section/edit/:id",
        name: "ui-section-edit",
        meta: {
          modules: {
            uiSectionModule: () => require("@/store/modules/UISectionModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_UI_EDIT],
        },
        component: () => import("@/views/uisection/EditUISection.vue"),
      },
      //Watermark
      {
        path: "/watermark",
        name: "watermark",
        meta: {
          modules: {
            watermarkModule: () => require("@/store/modules/WatermarkModule"),
          },
          roles: [Permission.PERMISSION_SETTING_VIEW],
        },
        component: () => import("@/views/watermark/Watermark.vue"),
      },
      {
        path: "/watermark/add",
        name: "watermark-add",
        meta: {
          modules: {
            watermarkModule: () => require("@/store/modules/WatermarkModule"),
          },
          roles: [Permission.PERMISSION_SETTING_CREATE],
        },
        component: () => import("@/views/watermark/AddWatermark.vue"),
      },
      {
        path: "/watermark/edit/:id",
        name: "watermark-edit",
        meta: {
          modules: {
            watermarkModule: () => require("@/store/modules/WatermarkModule"),
          },
          roles: [Permission.PERMISSION_SETTING_EDIT],
        },
        component: () => import("@/views/watermark/EditWatermark.vue"),
      },
      //Estate-Category
      {
        path: "/estate-category/type",
        name: "estate-category-type",
        meta: {
          modules: {
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATETYPES_VIEW],
        },
        component: () => import("@/views/estate-category/Types.vue"),
      },
      {
        path: "/estate-category/type/add",
        name: "estate-category-type-add",
        meta: {
          modules: {
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATETYPES_CREATE],
        },
        component: () => import("@/views/estate-category/AddType.vue"),
      },
      {
        path: "/estate-category/type/edit/:id",
        name: "estate-category-type-edit",
        meta: {
          modules: {
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATETYPES_EDIT],
        },
        component: () => import("@/views/estate-category/EditType.vue"),
      },
      {
        path: "/estate-category/form",
        name: "estate-category-form",
        meta: {
          modules: {
            formsModule: () => require("@/store/modules/FormsModule"),
          },
          roles: [Permission.PERMISSION_ESTATEFORM_VIEW],
        },
        component: () => import("@/views/estate-category/Forms.vue"),
      },
      {
        path: "/estate-category/form/add",
        name: "estate-category-form-add",
        meta: {
          modules: {
            formsModule: () => require("@/store/modules/FormsModule"),
          },
          roles: [Permission.PERMISSION_ESTATEFORM_CREATE],
        },
        component: () => import("@/views/estate-category/AddForm.vue"),
      },
      {
        path: "/estate-category/form/edit/:id",
        name: "estate-category-form-edit",
        meta: {
          modules: {
            formsModule: () => require("@/store/modules/FormsModule"),
          },
          roles: [Permission.PERMISSION_ESTATEFORM_EDIT],
        },
        component: () => import("@/views/estate-category/EditForm.vue"),
      },
      {
        path: "/estate-category/direction",
        name: "estate-category-direction",
        meta: {
          modules: {
            estateModule: () => require("@/store/modules/EstateModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate-category/Direction.vue"),
      },
      {
        path: "/estate-category/tag",
        name: "estate-category-tag",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_TAGS_VIEW],
        },
        component: () => import("@/views/estate-category/tag/Tag.vue"),
      },
      {
        path: "/estate-category/tag/add",
        name: "estate-category-add-tag",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_TAGS_CREATE],
        },
        component: () => import("@/views/estate-category/tag/AddTag.vue"),
      },
      {
        path: "/estate-category/tag/edit/:id",
        name: "estate-category-edit-tag",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_TAGS_EDIT],
        },
        component: () => import("@/views/estate-category/tag/EditTag.vue"),
      },
      //Estate
      {
        path: "/estate/add",
        name: "estate-add",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            estateModule: () => require("@/store/modules/EstateModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_CREATE],
        },
        component: () => import("@/views/estate/AddEstate.vue"),
      },
      {
        path: "/estate/:id",
        name: "estate-edit",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            estateModule: () => require("@/store/modules/EstateModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_EDIT],
        },
        component: () => import("@/views/estate/EditEstate.vue"),
      },
      {
        path: "/estate/browse/:id",
        name: "estate-browse",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            estateModule: () => require("@/store/modules/EstateModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_EDIT],
        },
        component: () => import("@/views/estate/EstateBrowse.vue"),
      },
      {
        path: "/estate/await",
        name: "estate-await",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            estateModule: () => require("@/store/modules/EstateModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/AwaitEstate.vue"),
      },
      {
        path: "/estate/approved",
        name: "estate-approved",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            estateModule: () => require("@/store/modules/EstateModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            priceVipModules: () => require("@/store/modules/PriceVipModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/ApprovedEstate.vue"),
      },
      {
        path: "/estate/hidden",
        name: "estate-hidden",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            estateModule: () => require("@/store/modules/EstateModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/HiddenEstate.vue"),
      },
      {
        path: "/estate/expired",
        name: "estate-expired",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            estateModule: () => require("@/store/modules/EstateModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/ExpiredEstate.vue"),
      },
      {
        path: "/estate/violated",
        name: "estate-violated",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            estateModule: () => require("@/store/modules/EstateModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/ViolatedEstate.vue"),
      },
      {
        path: "/estate/reports",
        name: "estate-reports",
        meta: {
          modules: {
            estateModule: () => require("@/store/modules/EstateModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/ReportEstate.vue"),
      },
      {
        path: "/estate/show/:id",
        name: "estate-show-details",
        meta: {
          modules: {
            estateModule: () => require("@/store/modules/EstateModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/EstateShowDetail.vue"),
      },
      {
        path: "/estate/comments",
        name: "estate-comments",
        meta: {
          modules: {
            estateModule: () => require("@/store/modules/EstateModule"),
            userAccountModules: () => require("@/store/modules/AccountModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/EstateComment.vue"),
      },
      {
        path: "/estate/comment/all",
        name: "estate-comment-all",
        meta: {
          modules: {
            estateModule: () => require("@/store/modules/EstateModule"),
            userAccountModules: () => require("@/store/modules/AccountModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/EstateCommentList.vue"),
      },
      {
        path: "/estate/comment/:id/state/:type",
        name: "estate-comment-info",
        meta: {
          modules: {
            estateModule: () => require("@/store/modules/EstateModule"),
            userAccountModules: () => require("@/store/modules/AccountModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/EstateCommentInfo.vue"),
      },
      {
        path: "/estate/await-update",
        name: "estate-await-update",
        meta: {
          modules: {
            estateModule: () => require("@/store/modules/EstateModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_AWAITEDIT],
        },
        component: () => import("@/views/estate/AwaitUpdateEstate.vue"),
      },
      {
        path: "/estate/return",
        name: "estate-return",
        meta: {
          modules: {
            estateModule: () => require("@/store/modules/EstateModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/EstateReturn.vue"),
      },
      {
        path: "/estate/await-update/:id",
        name: "estate-await-update-edit",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            estateModule: () => require("@/store/modules/EstateModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_AWAITEDIT],
        },
        component: () => import("@/views/estate/EditEstatePending.vue"),
      },
      //estate of user
      {
        path: "/user/:userId/estate/:type",
        name: "estate-user",
        meta: {
          modules: {
            estateModule: () => require("@/store/modules/EstateModule"),
          },
          roles: [Permission.PERMISSION_ESTATE_VIEW],
        },
        component: () => import("@/views/estate/UserEstate.vue"),
      },
      //travel
      {
        path: "/travel/tags",
        name: "travel-tags",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_TRAVELTAGS_VIEW],
        },
        component: () => import("@/views/travel/tag/Tag.vue"),
      },
      {
        path: "/travel/tag/add",
        name: "travel-tag-add",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_TRAVELTAGS_CREATE],
        },
        component: () => import("@/views/travel/tag/AddTag.vue"),
      },
      {
        path: "/travel/tag/edit/:id",
        name: "travel-tag-edit",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_TRAVELTAGS_EDIT],
        },
        component: () => import("@/views/travel/tag/EditTag.vue"),
      },
      {
        path: "/travel/types",
        name: "travel-types",
        meta: {
          modules: {
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_TRAVELTYPES_VIEW],
        },
        component: () => import("@/views/travel/type/Types.vue"),
      },
      {
        path: "/travel/type/add",
        name: "travel-type-add",
        meta: {
          modules: {
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_TRAVELTYPES_CREATE],
        },
        component: () => import("@/views/travel/type/AddType.vue"),
      },
      {
        path: "/travel/type/edit/:id",
        name: "travel-type-edit",
        meta: {
          modules: {
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_TRAVELTYPES_EDIT],
        },
        component: () => import("@/views/travel/type/EditType.vue"),
      },
      {
        path: "/travels",
        name: "travel-all",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            travelModule: () => require("@/store/modules/TravelModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            priceVipModules: () => require("@/store/modules/PriceVipModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_TRAVEL_VIEW],
        },
        component: () => import("@/views/travel/TravelAll.vue"),
      },
      {
        path: "/travels/expired",
        name: "travel-expired",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            travelModule: () => require("@/store/modules/TravelModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_TRAVEL_VIEW],
        },
        component: () => import("@/views/travel/TravelExpired.vue"),
      },
      {
        path: "/travels/violated",
        name: "travel-violated",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            travelModule: () => require("@/store/modules/TravelModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_TRAVEL_VIEW],
        },
        component: () => import("@/views/travel/TravelViolated.vue"),
      },

      {
        path: "/travels/reports",
        name: "travel-reports",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            travelModule: () => require("@/store/modules/TravelModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_TRAVEL_VIEW],
        },
        component: () => import("@/views/travel/TravelReport.vue"),
      },
      {
        path: "/travel/add",
        name: "travel-add",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            travelModule: () => require("@/store/modules/TravelModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_TRAVEL_CREATE],
        },
        component: () => import("@/views/travel/AddTravel.vue"),
      },
      {
        path: "/travel/edit/:id",
        name: "travel-edit",
        meta: {
          modules: {
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            travelModule: () => require("@/store/modules/TravelModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_TRAVEL_EDIT],
        },
        component: () => import("@/views/travel/EditTravel.vue"),
      },
      {
        path: "/comments",
        name: "comment-all",
        meta: {
          modules: {
            commentModules: () => require("@/store/modules/CommentModule"),
            userAccountModules: () => require("@/store/modules/AccountModule"),
          },
          roles: [Permission.PERMISSION_COMMENT_VIEW],
        },
        component: () => import("@/views/comment/CommentList.vue"),
      },
      {
        path: "/comment/news",
        name: "comment-news",
        meta: {
          modules: {
            commentModules: () => require("@/store/modules/CommentModule"),
            userAccountModules: () => require("@/store/modules/AccountModule"),
          },
          roles: [Permission.PERMISSION_COMMENT_VIEW],
        },
        component: () => import("@/views/comment/NewsComment.vue"),
      },
      {
        path: "/comment/travel",
        name: "comment-travel",
        meta: {
          modules: {
            userAccountModules: () => require("@/store/modules/AccountModule"),
            commentModules: () => require("@/store/modules/CommentModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_COMMENT_VIEW],
        },
        component: () => import("@/views/comment/TravelComment.vue"),
      },
      {
        path: "/comment/estate",
        name: "comment-estate",
        meta: {
          modules: {
            userAccountModules: () => require("@/store/modules/AccountModule"),
            commentModules: () => require("@/store/modules/CommentModule"),
            formsModule: () => require("@/store/modules/FormsModule"),
            estateCatoryTypeModule: () =>
              require("@/store/modules/EstateCategoryTypeModule"),
          },
          roles: [Permission.PERMISSION_COMMENT_VIEW],
        },
        component: () => import("@/views/comment/EstateComment.vue"),
      },
      {
        path: "/comment/:id/:type/:status",
        name: "comment-info",
        meta: {
          modules: {
            userAccountModules: () => require("@/store/modules/AccountModule"),
            commentModules: () => require("@/store/modules/CommentModule"),
          },
          roles: [Permission.PERMISSION_COMMENT_VIEW],
        },
        component: () => import("@/views/comment/CommentInfo.vue"),
      },
      //url banned
      {
        path: "/url/banned",
        name: "url-banned",
        meta: {
          modules: {
            urlBannedModules: () => require("@/store/modules/UrlBannedModule"),
          },
          roles: [Permission.PERMISSION_ACCOUNTUSER_URLDENIED],
        },
        component: () => import("@/views/urlbanned/UrlBanned.vue"),
      },
      //keyword banned
      {
        path: "/comments/keywords",
        name: "keyword-banned",
        meta: {
          modules: {
            keywordBannedModules: () =>
              require("@/store/modules/KeywordBannedModule"),
          },
          roles: [Permission.PERMISSION_COMMENT_KEYWORD_DENIED],
        },
        component: () => import("@/views/keywords/Keyword.vue"),
      },
      // user account
      {
        path: "/accounts/:phone?",
        name: "user-account",
        meta: {
          modules: {
            userAccountModules: () => require("@/store/modules/AccountModule"),
            tourGuideModules: () => require("@/store/modules/TourGuideModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
          },
          roles: [Permission.PERMISSION_ACCOUNTUSER_VIEW],
        },
        component: () => import("@/views/users/UserAccount.vue"),
      },
      {
        path: "/account/add",
        name: "account-add",
        meta: {
          modules: {
            userAccountModules: () => require("@/store/modules/AccountModule"),
          },
          roles: [Permission.PERMISSION_ACCOUNTUSER_CREATE],
        },
        component: () => import("@/views/users/AddUser.vue"),
      },
      {
        path: "/account/edit/:id",
        name: "account-edit",
        meta: {
          modules: {
            countryModule: () => require("@/store/modules/CountryModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
            positionModule: () => require("@/store/modules/PositionModule"),
            userAccountModules: () => require("@/store/modules/AccountModule"),
          },

          roles: [Permission.PERMISSION_ACCOUNTUSER_EDIT],
        },
        component: () => import("@/views/users/EditUser.vue"),
      },
      {
        path: "/account/log/:id",
        name: "account-logs",
        meta: {
          modules: {
            userAccountModules: () => require("@/store/modules/AccountModule"),
          },

          roles: [Permission.PERMISSION_ACCOUNTUSER_VIEW],
        },
        component: () => import("@/views/users/UserLog.vue"),
      },
      {
        path: "/interests",
        name: "account-interests",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
          },

          roles: [Permission.PERMISSION_ACCOUNTUSER_INTEREST],
        },
        component: () => import("@/views/users/interests/Interests.vue"),
      },
      {
        path: "/accounts/cmnd",
        name: "account-code",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
            userAccountModules: () => require("@/store/modules/AccountModule"),
            tourGuideModules: () => require("@/store/modules/TourGuideModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
            wardModule: () => require("@/store/modules/WardModule"),
          },

          roles: [Permission.PERMISSION_ACCOUNTUSER_VIEW],
        },
        component: () => import("@/views/users/UserCMND.vue"),
      },
      {
        path: "/interest/add",
        name: "account-interest-add",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
          },

          roles: [Permission.PERMISSION_ACCOUNTUSER_INTEREST],
        },
        component: () => import("@/views/users/interests/AddInterest.vue"),
      },
      {
        path: "/interest/edit/:id",
        name: "account-interest-edit",
        meta: {
          modules: {
            TagModule: () => require("@/store/modules/TagModule"),
          },

          roles: [Permission.PERMISSION_ACCOUNTUSER_INTEREST],
        },
        component: () => import("@/views/users/interests/EditInterest.vue"),
      },
      {
        path: "/account/:id/transactions",
        name: "account-transactions",
        meta: {
          modules: {
            userAccountModules: () => require("@/store/modules/AccountModule"),
          },

          roles: [Permission.PERMISSION_ACCOUNTUSER_VIEW],
        },
        component: () => import("@/views/users/UserTransaction.vue"),
      },
      //price vips
      {
        path: "/price/vips",
        name: "price-vips",
        meta: {
          modules: {
            priceVipModules: () => require("@/store/modules/PriceVipModule"),
          },
          roles: [Permission.PERMISSION_PRICEVIP_VIEW],
        },
        component: () => import("@/views/pricevip/PriceVips.vue"),
      },
      {
        path: "/price/vip/add",
        name: "price-vip-add",
        meta: {
          modules: {
            priceVipModules: () => require("@/store/modules/PriceVipModule"),
          },

          roles: [Permission.PERMISSION_PRICEVIP_CREATE],
        },
        component: () => import("@/views/pricevip/AddPriceVip.vue"),
      },
      //price posts
      {
        path: "/price/posts",
        name: "price-posts",
        meta: {
          modules: {
            pricePostModules: () => require("@/store/modules/PricePostModule"),
          },
          roles: [Permission.PERMISSION_PRICEPOST_VIEW],
        },
        component: () => import("@/views/pricePost/PricePosts.vue"),
      },
      {
        path: "/price/post/add",
        name: "price-post-add",
        meta: {
          modules: {
            pricePostModules: () => require("@/store/modules/PricePostModule"),
          },
          roles: [Permission.PERMISSION_PRICEPOST_CREATE],
        },
        component: () => import("@/views/pricePost/AddPricePost.vue"),
      },
      //tourguide
      {
        path: "/tourguides",
        name: "tour-guides",
        meta: {
          modules: {
            tourGuideModules: () => require("@/store/modules/TourGuideModule"),
            userAccountModules: () => require("@/store/modules/AccountModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_ACCOUNTUSER_HDV],
        },
        component: () => import("@/views/tourguide/TourGuides.vue"),
      },
      //tourguide
      {
        path: "/tourguide/peding",
        name: "tour-guide-peding",
        meta: {
          modules: {
            tourGuideModules: () => require("@/store/modules/TourGuideModule"),
            userAccountModules: () => require("@/store/modules/AccountModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_ACCOUNTUSER_HDV],
        },
        component: () => import("@/views/tourguide/TourGuidePending.vue"),
      },
      //banners
      {
        path: "/banner/articles",
        name: "banner-articles",
        meta: {
          modules: {
            bannerModules: () => require("@/store/modules/BannerModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_BANNER_VIEW],
        },
        component: () => import("@/views/banner/BannerArticel.vue"),
      },
      {
        path: "/banner/article/add",
        name: "banner-articel-add",
        meta: {
          modules: {
            bannerModules: () => require("@/store/modules/BannerModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_BANNER_VIEW],
        },
        component: () => import("@/views/banner/AddBannerArticel.vue"),
      },
      {
        path: "/banner/article/edit/:id",
        name: "banner-articel-edit",
        meta: {
          modules: {
            bannerModules: () => require("@/store/modules/BannerModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_BANNER_EDIT],
        },
        component: () => import("@/views/banner/EditBannerArticel.vue"),
      },
      {
        path: "/banner/travels",
        name: "banner-travels",
        meta: {
          modules: {
            bannerModules: () => require("@/store/modules/BannerModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_BANNER_VIEW],
        },
        component: () => import("@/views/banner/BannerTravel.vue"),
      },
      {
        path: "/banner/travel/add",
        name: "banner-travel-add",
        meta: {
          modules: {
            bannerModules: () => require("@/store/modules/BannerModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_BANNER_CREATE],
        },
        component: () => import("@/views/banner/AddBannerTravel.vue"),
      },
      {
        path: "/banner/edit/:id",
        name: "banner-edit",
        meta: {
          modules: {
            bannerModules: () => require("@/store/modules/BannerModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_BANNER_EDIT],
        },
        component: () => import("@/views/banner/EditBanner.vue"),
      },
      {
        path: "/banner/estates",
        name: "banner-estates",
        meta: {
          modules: {
            bannerModules: () => require("@/store/modules/BannerModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_BANNER_VIEW],
        },
        component: () => import("@/views/banner/BannerEstate.vue"),
      },
      {
        path: "/banner/estate/add",
        name: "banner-estate-add",
        meta: {
          modules: {
            bannerModules: () => require("@/store/modules/BannerModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },

          roles: [Permission.PERMISSION_BANNER_CREATE],
        },
        component: () => import("@/views/banner/AddBannerEstate.vue"),
      },
      {
        path: "/booking/expired",
        name: "booking-expired",
        meta: {
          modules: {
            bookingModules: () => require("@/store/modules/BookingBuddyModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },
          roles: [Permission.PERMISSION_BOOKINGBUDDY_VIEW],
        },
        component: () => import("@/views/booking/BookingExpired.vue"),
      },
      {
        path: "/booking/auction",
        name: "booking-auction",
        meta: {
          modules: {
            bookingModules: () => require("@/store/modules/BookingBuddyModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },
          roles: [Permission.PERMISSION_BOOKINGBUDDY_VIEW],
        },
        component: () => import("@/views/booking/BookingAucation.vue"),
      },
      {
        path: "/booking/violative",
        name: "booking-violative",
        meta: {
          modules: {
            bookingModules: () => require("@/store/modules/BookingBuddyModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },
          roles: [Permission.PERMISSION_BOOKINGBUDDY_VIEW],
        },
        component: () => import("@/views/booking/BookingViolative.vue"),
      },
      {
        path: "/booking/report",
        name: "booking-report",
        meta: {
          modules: {
            bookingModules: () => require("@/store/modules/BookingBuddyModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
            districtModule: () => require("@/store/modules/DistrictModule"),
          },
          roles: [Permission.PERMISSION_BOOKINGBUDDY_VIEW],
        },
        component: () => import("@/views/booking/BookingReport.vue"),
      },
      {
        path: "/booking/request-violative/:id",
        name: "booking-request-violative",
        meta: {
          modules: {
            bookingModules: () => require("@/store/modules/BookingBuddyModule"),
          },
          roles: [Permission.PERMISSION_BOOKINGBUDDY_VIEW],
        },
        component: () => import("@/views/booking/BookingRequestViolative.vue"),
      },
      {
        path: "/booking/violative/info/:id",
        name: "booking-violative-info",
        meta: {
          modules: {
            bookingModules: () => require("@/store/modules/BookingBuddyModule"),
          },
          roles: [Permission.PERMISSION_BOOKINGBUDDY_VIEW],
        },
        component: () => import("@/views/booking/BookingViolativeInfo.vue"),
      },
      //Commitment Pack
      {
        path: "/commitment-pack",
        name: "commitment-pack",
        meta: {
          modules: {
            watermarkModule: () =>
              require("@/store/modules/CommitmentPackModule"),
          },
          roles: [Permission.PERMISSION_COMMITMENTPACK_VIEW],
        },
        component: () => import("@/views/commitment-pack/CommitmentPack.vue"),
      },
      {
        path: "/commitment-pack/add",
        name: "commitment-pack-add",
        meta: {
          modules: {
            watermarkModule: () => require("@/store/modules/WatermarkModule"),
          },
          roles: [Permission.PERMISSION_COMMITMENTPACK_CREATE],
        },
        component: () =>
          import("@/views/commitment-pack/AddCommitmentPack.vue"),
      },
      {
        path: "/commitment-pack/edit/:id",
        name: "commitment-pack-edit",
        meta: {
          modules: {
            watermarkModule: () => require("@/store/modules/WatermarkModule"),
          },
          roles: [Permission.PERMISSION_COMMITMENTPACK_EDIT],
        },
        component: () =>
          import("@/views/commitment-pack/EditCommitmentPack.vue"),
      },
      //Report
      // {
      //   path: "/report/account",
      //   name: "report-account",
      //   meta: {
      //     modules: {
      //       reportModule: () => require("@/store/modules/ReportModule"),
      //       provinceModule: () => require("@/store/modules/ProvinceModule"),
      //     },
      //     //roles: [Permission.PERMISSION_COMMITMENTPACK_VIEW],
      //   },
      //   component: () => import("@/views/report/account.vue"),
      // },
      {
        path: "/report/account",
        name: "report-account",
        meta: {
          modules: {
            reportModule: () => require("@/store/modules/ReportModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_REPORT_ACCOUNT],
        },
        component: () => import("@/views/report/account.vue"),
      },
      {
        path: "/report/posts",
        name: "report-posts",
        meta: {
          modules: {
            reportModule: () => require("@/store/modules/ReportModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_REPORT_POSTS],
        },
        component: () => import("@/views/report/posts.vue"),
      },
      {
        path: "/report/postsVip",
        name: "report-postsVip",
        meta: {
          modules: {
            reportModule: () => require("@/store/modules/ReportModule"),
            provinceModule: () => require("@/store/modules/ProvinceModule"),
          },
          roles: [Permission.PERMISSION_REPORT_POSTVIP],
        },
        component: () => import("@/views/report/postsVip.vue"),
      },
      {
        path: "/feedbacks",
        name: "feedbacks",
        meta: {
          modules: {
            feedbackModule: () => require("@/store/modules/FeedbackModule"),
          },
          roles: [Permission.PERMISSION_FEEDBACK_VIEW],
        },
        component: () => import("@/views/feedback/feedback.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: any, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  await store.dispatch(Actions.VERIFY_AUTH, {
    api_token: JwtService.getToken(),
  });
  if (to.meta.modules) {
    for (const key in to.meta.modules) {
      const module = to.meta.modules[key]();
      if (!Object.prototype.hasOwnProperty.call(store.state, key)) {
        store.registerModule(key, module.default);
      }
    }
  }
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  const roles = JwtService.getRoles()?.split(",") as string[];

  const roleTos = to.meta?.roles ?? to.redirectedFrom?.meta?.roles;
  if (to.name === "dashboard") {
    next();
  } else if (roleTos) {
    const isMatch = roleTos.some((role) => roles.includes(role));
    if (!isMatch) {
      return next("/dashboard");
    } else {
      next();
    }
  } else {
    next();
  }
});
router.afterEach((to: any, from: any) => {
  // unregister modules
  if (from.meta.modules) {
    for (const key in from.meta.modules) {
      if (
        to.meta.modules &&
        Object.prototype.hasOwnProperty.call(to.meta.modules, key)
      ) {
        continue;
      }
      if (Object.prototype.hasOwnProperty.call(store.state, key)) {
        store.unregisterModule(key);
      }
    }
  }
});
export default router;
