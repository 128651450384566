import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ValidationError, ResultObj } from "../../interfaces/IApiResult";
import { groupBy } from "@/core/helpers/extension";

import {
  Role,
  PermissionData,
  UserAdminModel,
} from "../../interfaces/Permission";

@Module
export default class RoleModule extends VuexModule {
  errors = {} as ValidationError[];
  roles = {} as ResultObj<Role>;
  roleselected = {} as Role[];
  permission = {} as PermissionData;
  userAdmins = {} as ResultObj<UserAdminModel>;
  userAdmin = {} as UserAdminModel;
  /**
   * Get roles
   * @returns ResultObj<Role>
   */
  get getRoles(): ResultObj<Role> {
    return this.roles;
  }
  /**
   * Get roleselected
   * @returns Role[]
   */
  get getRoleSeleted(): Role[] {
    return this.roleselected;
  }
  /**
   * Get roleselected
   * @returns ResultObj<UserAdminModel>
   */
  get getUserAdmins() {
    return this.userAdmins;
  }
  /**
   * Get roleselected
   * @returns UserAdminModel
   */
  get getUserAdminInfo() {
    return this.userAdmin;
  }
  /**
   * Get permission
   * @returns Permission
   */
  get getPermission() {
    const permission = this.permission;
    if (Object.keys(permission).length) {
      permission.roleClaims = groupBy(permission.roleClaims, "type");
    }
    return permission;
  }
  @Mutation
  [Mutations.RESET_PERMISSIONS](): void {
    this.permission = {} as PermissionData;
  }
  @Mutation
  [Mutations.SET_ALL_ROLES](rolePayload): void {
    this.roles = rolePayload;
  }
  @Mutation
  [Mutations.SET_PERMISSION](payload): void {
    this.permission = payload;
  }
  @Mutation
  [Mutations.SET_ROLE_SELECT](payload): void {
    this.roleselected = payload;
  }
  @Mutation
  [Mutations.SET_USERS_ADMIN](payload): void {
    this.userAdmins = payload;
  }
  @Mutation
  [Mutations.SET_USER_ADMIN](payload): void {
    this.userAdmin = payload;
  }
  @Mutation
  [Mutations.RESET_USER_ADMIN](): void {
    this.userAdmin = {} as UserAdminModel;
    this.errors = {} as ValidationError[];
  }

  @Action
  [Actions.GET_ALL_ROLES](pageIndex = 1) {
    return ApiService.query(`/admin/Roles`, {
      params: { page: pageIndex, pageSize: 10 },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_ROLES, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }
  @Action
  [Actions.ADD_NEW_ROLE](role) {
    return ApiService.post(`/admin/Role`, role)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.EDIT_ROLE](role) {
    return ApiService.put(`/admin/Role/${role.id}`, role)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.DELETE_ROLE](role) {
    return ApiService.delete(`/admin/Role/${role}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_PERMISSION](payload) {
    return ApiService.get(`/admin/Role/${payload}/permission`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PERMISSION, data.resultObj);
      })
      .catch(() => {
        // router.push({ name: "roles-user" });
        //this.context.commit(Mutations.REDIRECT_ROUTER);
        this.context.commit(Mutations.RESET_PERMISSIONS);
      });
  }
  @Action
  [Actions.UPDATE_PERMISSION](payload) {
    return ApiService.put(
      `/admin/Role/${payload.roleId}/permission`,
      payload.roleClaims
    )
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.CHANGE_PASSWORD](payload) {
    console.log(payload);
    return ApiService.post(`/admin/userAdmin/changePassword`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.ADD_USER_ADMIN](payload) {
    return ApiService.post(`/admin/Account`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_ROLE_SELECT]() {
    return ApiService.get(`/admin/role/selected`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLE_SELECT, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_USERS_ADMIN](pageIndex = 1) {
    return ApiService.query(`/admin/account/userAdmins`, {
      params: { page: pageIndex, pageSize: 10 },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USERS_ADMIN, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_USER_ADMIN](payload) {
    return ApiService.get(`/admin/userAdmin/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_ADMIN, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.EDIT_USER_ADMIN](payload) {
    return ApiService.patch(`/admin/userAdmin/${payload.userId}`, payload.data)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
}
