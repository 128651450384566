import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <ActionTree<State, any>>{
  [Actions.GET_BOOKINGS](store, payload) {
    return ApiService.query(`/admin/bookings`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_BOOKINGS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_BOOKING_INFO](store, payload) {
    return ApiService.get(`/admin/booking/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_BOOKING_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_BOOKING_BID](store, payload) {
    return ApiService.query(`/admin/booking/${payload.id}/bid`, {
      params: payload.data,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_BOOKING_BID, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_BOOKING_REPORTS](store, payload) {
    return ApiService.query(`/admin/booking/reports/${payload}`, {
      params: payload.data,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_BOOKING_REPORTS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_BOOKING_VIOLATIVE](store, payload) {
    return ApiService.patch(
      `/admin/booking/violative/${payload.id}`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_BOOKING_VIOLATIVE](store, payload) {
    return ApiService.get(`/admin/booking/violative/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_BOOKING_VIOLATIVE, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
};
