export interface IArticle {
  id: number;
  name: string;
  categoryName: string;
  image: string;
  published: boolean;
}

export enum ArticleOrder {
  DESC = 1,
  ASC = 2,
}

export interface ArticleQuery {
  page: number;
  pageSize: number;
  order?: ArticleOrder;
  published?: boolean;
  articleCategoryId?: number;
  tagId?: number;
  name: string;
  tagName: string;
}

export interface ArticleTranslation {
  language: string;
  isDefaultLanguage: boolean;
  name: string;
  slug: string;
  h1: string;
  short: string;
  body: string;
  seoTitle: string;
  seoDescription: string;
  seoKeywords: string;
}

export enum EArticleTagType {
  Default = 1,
  RelatedTop = 2,
  RelatedBot = 3,
}

export interface ArticleTag {
  tagId: number;
  type: EArticleTagType;
}

export interface ArticleCreateModel {
  imageFile: File;
  videoFile: File;
  categoryId: number;
  published: boolean;
  special: boolean;
  isBlockComment: boolean;
  isVideo: boolean;
  articleTranslations: ArticleTranslation[];
}

export interface ArticleCategoryDetail {
  id: number;
  image: string;
  imageFile: File;
  videoFile: File;
  video: string;
  categoryId: number;
  published: boolean;
  publishedSocial: boolean;
  special: boolean;
  isVideo: boolean;
  createdTime: any;
  isBlockComment: boolean;
  articleTags: ArticleTag[];
  articleTranslations: ArticleTranslation[];
}
