import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentWatermarks(state: State) {
    return state.watermarks;
  },
  currentWatermarkInfo(state: State) {
    return state.watermarkInfo;
  },
  currentWatermarkSelect(state: State) {
    return state.watermarkSelect;
  },
};
