import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <ActionTree<State, any>>{
  [Actions.GET_PRICE_VIPS](store, payload) {
    return ApiService.query(`/admin/pricevips`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_VIPS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_PRICE_VIP_INFO](store, payload) {
    return ApiService.get(`/admin/pricevip/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_VIP_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.ADD_PRICE_VIP](store, payload) {
    return ApiService.post(`/admin/pricevip`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_PRICE_VIP](store, payload) {
    return ApiService.put(`/admin/pricevip/${payload.id}`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_PRICE_VIP_TYPE](store) {
    return ApiService.get(`/admin/pricevip/type`)
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_VIP_TYPE, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_PRICE_VIP_PROVINCE](store, payload) {
    return ApiService.get(`/admin/pricevip/province/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_VIP_PROVINCE, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_PRICE_VIP_PROVINCE](store, payload) {
    return ApiService.patch(
      `/admin/pricevip/province/${payload.id}`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_PRICE_VIP_DISTRICT](store, payload) {
    return ApiService.get(`/admin/pricevip/district/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_PRICE_VIP_DISTRICT, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_PRICE_VIP_DISTRICT](store, payload) {
    return ApiService.patch(`/admin/pricevip/district/${payload.id}`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPGRADE_VIP](store, payload) {
    return ApiService.put(`/admin/pricevip/upgradeVip`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.RESET_PRICE_VIP_PROVINCE](store) {
    store.commit(Mutations.SET_PRICE_VIP_PROVINCE, {});
  },
  [Actions.RESET_PRICE_VIP_DISTRICT](store) {
    store.commit(Mutations.SET_PRICE_VIP_DISTRICT, {});
  },
};
