import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
export default <ActionTree<State, any>>{
  [Actions.GET_KEYWORDS_BANNED](store) {
    return ApiService.get(`/admin/keywords`)
      .then(({ data }) => {
        store.commit(Mutations.SET_KEYWORDS_BANNED, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },

  [Actions.ADD_KEYWORDS_BANNED](store, payload) {
    return ApiService.post(`/admin/keyword/create`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.DELETE_KEYWORDS_BANNED](store, payload) {
    return ApiService.post(`/admin/keyword/delete`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_KEYWORDS_BANNED](store, payload) {
    return ApiService.put(`/admin/keyword`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
};
