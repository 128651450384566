import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentUIForms(state: State) {
    return state.uiforms;
  },
  currentUIFormInfo(state: State) {
    return state.uiformInfo;
  },
  currentUIFormSelect(state: State) {
    return state.uiformSelect;
  },
  currentUIFormCreate(state: State) {
    return state.uiformCreate;
  },
};
