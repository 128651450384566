import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
export default <ActionTree<State, any>>{
  [Actions.GET_USER_ACCOUNTS](store, payload) {
    return ApiService.query(`/admin/account/users`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_USER_ACCOUNT, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.ADD_USER_ACCOUNT](store, payload) {
    return ApiService.post(`/admin/account/user`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_USER_ACCOUNT_INFO](store, payload) {
    return ApiService.get(`/admin/account/user/${payload}`)
      .then(({ data }) => {
        console.log(data.resultObj);
        store.commit(Mutations.SET_USER_ACCOUNT_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.EDIT_USER_ACCOUNT](store, payload) {
    return ApiService.put(`/admin/account/user`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_TRAVEL_REQUEST_USER_ACCOUNT](store, payload) {
    return ApiService.patchData(`/admin/account/user/${payload}/request-travel`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_PASSWORD_USER_ACCOUNT](store, payload) {
    return ApiService.patch(
      `/admin/account/user/${payload.id}/reset-password`,
      payload
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.APPROVED_CODE_USER_ACCOUNT](store, payload) {
    return ApiService.patch(
      `/admin/account/user/${payload.id}/approved-code/${payload.type}`,
      payload
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.CANCELAPPROVED_CODE_USER_ACCOUNT](store, payload) {
    return ApiService.patch(
      `/admin/account/user/${payload.id}/cancel-approved-code/${payload.type}`,
      payload
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_CODE_USER_ACCOUNT](store, payload) {
    return ApiService.patch(
      `/admin/account/user/${payload.id}/code/`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.DELETE_USER_ACCOUNT](store, payload) {
    return ApiService.delete(`/admin/account/user/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_USER_ACCOUNT_LOGS](store, payload) {
    return ApiService.query(`/admin/account/user/logs`, { params: payload })
      .then(({ data }) => {
        console.log(data);
        store.commit(Mutations.SET_USER_ACCOUNT_LOGS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_STATUS_USER_ACCOUNT](store, payload) {
    return ApiService.patch(
      `/admin/account/user/${payload.userId}/change-status`,
      payload.data
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },

  [Actions.CANCEL_STATUS_USER_ACCOUNT](store, payload) {
    return ApiService.patchData(
      `/admin/account/user/${payload.userId}/cancel-block/${payload.type}`
    )
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.DONATION_USER_ACCOUNT](store, payload) {
    return ApiService.patch(`/admin/balance/donation`, payload.data)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  //wallet transaction
  [Actions.GET_USER_TRANSACTIONS](store, payload) {
    return ApiService.query(`/admin/transactions`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_USER_TRANSACTIONS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
};
