import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentUserAccounts(state: State) {
    return state.userAccounts;
  },
  currentUserAccountInfo(state: State) {
    return state.userAccountInfo;
  },
  currentUserAccountLog(state: State) {
    return state.userAccountLogs;
  },
  currentUserTransactions(state: State) {
    return state.userTransactions;
  },
};
