export class State {
  reportAccount = {};
  reportPosts = {};
  reportPostVip = {};
  reportPostsInfo = {};
  reportPostsVipInfo = {};
  reportTraffic = {};
  reportOrder = {};
  reportProduct = {};
  reportSeller = {};
  reportTransaction = {};
  reportExcel = {};
}
