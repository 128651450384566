import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ValidationError } from "../../interfaces/IApiResult";
import { MenuParentModel, MenuModel } from "../../interfaces/Config";

@Module
export default class MenuModule extends VuexModule {
  errors = {} as ValidationError[];
  menuParents = {} as MenuParentModel[];
  menus = {} as MenuModel[];
  menuInfo = {};
  menuSort = {};
  menuIcon = "" as string;
  /**
   * Get getMenuParent
   * @returns MenuParentModel[]
   */
  get getMenuParent(): MenuParentModel[] {
    return this.menuParents;
  }
  /**
   * Get getMenus
   * @returns MenuModel[]
   */
  get currentMenu(): MenuModel[] {
    return this.menus;
  }
  /**
   * Get menuInfo
   * @returns menuInfo
   */
  get currentMenuInfo() {
    return this.menuInfo;
  }
  /**
   * Get menuInfo
   * @returns menuInfo
   */
  get currentMenuSort() {
    return this.menuSort;
  }
  /**
   * Get menuIcon
   * @returns menuIcon
   */
  get currentMenuIcon(): string {
    return this.menuIcon;
  }
  @Mutation
  [Mutations.SET_MENU_PARENTS](payload): void {
    this.menuParents = payload;
  }
  @Mutation
  [Mutations.SET_MENUS](payload): void {
    this.menus = payload;
  }
  @Mutation
  [Mutations.SET_MENU_INFO](payload): void {
    this.menuInfo = payload;
  }
  @Mutation
  [Mutations.RESET_MENU_INFO](): void {
    this.menuInfo = {};
  }
  @Mutation
  [Mutations.SET_MENU_SORT](payload): void {
    this.menuSort = payload;
  }
  @Mutation
  [Mutations.RESET_MENU_SORT](): void {
    this.menuSort = {};
  }
  @Mutation
  [Mutations.SET_ICON_MENU](payload): void {
    this.menuIcon = payload;
  }
  @Action
  [Actions.GET_MENU_PARENTS]() {
    return ApiService.get(`/admin/menu/parents`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENU_PARENTS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENU_PROFILE_PARENTS]() {
    return ApiService.get(`/admin/menu-profile/parents`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENU_PARENTS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.ADD_MENU](menu) {
    return ApiService.post(`/admin/menu`, menu)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENUS]() {
    return ApiService.get(`/admin/menus`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENUS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENUTOPS]() {
    return ApiService.get(`/admin/menutops`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENUS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENU_ESTATE_TOPS]() {
    return ApiService.get(`/admin/menu-estates`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENUS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENU_ESTATE_LOCATION_TOPS]() {
    return ApiService.get(`/admin/menu-estate-location`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENUS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENU_PROFILE]() {
    return ApiService.get(`/admin/menu-profile`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENUS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENU_TRAVEL_TOPS]() {
    return ApiService.get(`/admin/menu-travels`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENUS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENU_TRAVEL_LOCATION_TOPS]() {
    return ApiService.get(`/admin/menu-travel-location`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENUS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENU_SECTIONS]() {
    return ApiService.get(`/admin/menu-section`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENUS, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENU_INFO](payload) {
    return ApiService.get(`/admin/menu/${payload}`)
      .then(({ data }) => {
        console.log(data.resultObj);
        this.context.commit(Mutations.SET_MENU_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.EDIT_MENU](payload) {
    return ApiService.put(`/admin/menu/${payload.id}`, payload)
      .then(({ data }) => {
        console.log(data.resultObj);
        this.context.commit(Mutations.RESET_MENU_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.DELETE_MENU](payload) {
    return ApiService.delete(`/admin/menu/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_MENU_SORT](payload) {
    return ApiService.get(`/admin/menu/sort/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MENU_SORT, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.EDIT_MENU_SORT](payload) {
    return ApiService.patch(`/admin/menu/sort`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_MENU_SORT);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_ICON_MENU](payload) {
    return ApiService.get(`/admin/menu/icon/${payload}`)
      .then(({ data }) => {
        console.log(data.resultObj);
        this.context.commit(Mutations.SET_ICON_MENU, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.EDIT_ICON_MENU](payload) {
    return ApiService.patch(`/admin/menu/icon/${payload.id}`, payload.data)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.UPDATE_MENU_SORT](payload) {
    this.context.commit(Mutations.SET_MENU_SORT, payload);
  }
}
