import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { IDistrict, DistrictSelect } from "@/interfaces/IDistrict";
import { ResultObj } from "@/interfaces/IApiResult";

@Module
export default class DistrictModule extends VuexModule {
  districts = {} as ResultObj<IDistrict>;
  districtInfo = {} as IDistrict;
  districtSelect = {} as DistrictSelect[];
  districtSelectMultiple = {};
  /**
   * Get districts
   * @returns IProvince[]
   */
  get currentDistricts(): ResultObj<IDistrict> {
    return this.districts;
  }
  /**
   * Get districts Select
   * @returns DistrictSelect[]
   */
  get getDistrictSeleted(): DistrictSelect[] {
    return this.districtSelect;
  }
  /**
   * Get districts Select
   * @returns DistrictSelect[]
   */
  get getDistrictSeletedMultiple() {
    return this.districtSelectMultiple;
  }
  /**
   * Get district Info
   * @returns IDistrict
   */
  get currentDistrictInfo(): IDistrict {
    return this.districtInfo;
  }

  @Mutation
  [Mutations.SET_DISTRICTS](payload): void {
    this.districts = payload;
  }
  @Mutation
  [Mutations.SET_DISTRICT_SELECT](payload): void {
    this.districtSelect = payload;
  }
  @Mutation
  [Mutations.SET_DISTRICT_INFO](payload): void {
    this.districtInfo = payload;
  }
  @Mutation
  [Mutations.SET_DISTRICT_MULTIPLE](payload): void {
    this.districtSelectMultiple = payload;
  }
  @Mutation
  [Mutations.RESET_DISTRICT](): void {
    this.districts = {} as ResultObj<IDistrict>;
    this.districtInfo = {} as IDistrict;
    this.districtSelect = {} as DistrictSelect[];
    this.districtSelectMultiple = {};
  }

  @Action
  [Actions.GET_DISTRICTS](payload) {
    return ApiService.query(`/admin/districts`, { params: payload })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISTRICTS, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }
  @Action
  [Actions.GET_DISTRICT_INFO](payload) {
    return ApiService.get(`/admin/district/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISTRICT_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.RESET_DISTRICT_INFO](payload) {
    this.context.commit(Mutations.SET_DISTRICT_INFO, null);
  }
  @Action
  [Actions.GET_DISTRICT_SELECT](payload) {
    return ApiService.get(`/admin/district/select/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISTRICT_SELECT, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_DISTRICT_MULTIPLE](payload) {
    return ApiService.post(`/admin/district/selects`, payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DISTRICT_MULTIPLE, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.ADD_DISTRICT](payload) {
    return ApiService.post(`/admin/district`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
        //this.context.commit(Mutations.RESET_COUNTRY_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.EDIT_DISTRICT](payload) {
    return ApiService.put(`/admin/district/${payload.id}`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
        //this.context.commit(Mutations.RESET_COUNTRY_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.DELETE_DISTRICT](payload) {
    return ApiService.delete(`/admin/district/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
        // this.context.commit(Mutations.RESET_PROVINCE);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
}
