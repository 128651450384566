import { GetterTree } from "vuex";
import { State } from "./state";

export default <GetterTree<State, any>>{
  currentTourGuides(state: State) {
    return state.tourGuides;
  },
  currentTourGuideInfo(state: State) {
    return state.tourGuideInfo;
  },
  currentTourGuidePending(state: State) {
    return state.tourGuidePending;
  },
};
