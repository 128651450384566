import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <MutationTree<State>>{
  [Mutations.SET_USER_ACCOUNT](state, payload) {
    state.userAccounts = payload;
  },
  [Mutations.SET_USER_ACCOUNT_INFO](state, payload) {
    state.userAccountInfo = payload;
  },
  [Mutations.SET_USER_ACCOUNT_LOGS](state, payload) {
    state.userAccountLogs = payload;
  },
  [Mutations.SET_USER_TRANSACTIONS](state, payload) {
    state.userTransactions = payload;
  },
};
