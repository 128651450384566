import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ValidationError, ResultObj } from "../../interfaces/IApiResult";
import { StaticPageDetail, StaticPageModel } from "../../interfaces/StaticPage";

@Module
export default class StaticPageModule extends VuexModule {
  errors = {} as ValidationError[];
  pages = {} as ResultObj<StaticPageModel>;
  pageSelect = {} as StaticPageModel[];
  pageInfo = {} as StaticPageDetail;
  imagePage = {};
  /**
   * Get roles
   * @returns ResultObj<StaticPageModel>
   */
  get currentPages(): ResultObj<StaticPageModel> {
    return this.pages;
  }
  /**
   * Get pageelected
   * @returns StaticPageModel[]
   */
  get getPageSeleted(): StaticPageModel[] {
    return this.pageSelect;
  }
  /**
   * Get permission
   * @returns Permission
   */
  get currentPageInfo(): StaticPageDetail {
    return this.pageInfo;
  }
  /**
   * Get image Page
   * @returns any
   */
  get currentImageStaticPage() {
    return this.imagePage;
  }

  @Mutation
  [Mutations.SET_PAGES](payload): void {
    this.pages = payload;
  }
  @Mutation
  [Mutations.SET_PAGE_INFO](payload): void {
    this.pageInfo = payload;
  }
  @Mutation
  [Mutations.SET_IMAGE_PAGE](payload): void {
    this.imagePage = payload;
  }
  @Mutation
  [Mutations.SET_PAGE_SELECT](payload): void {
    this.pageSelect = payload;
  }
  @Mutation
  [Mutations.RESET_PAGE](): void {
    this.pageInfo = {} as StaticPageDetail;
    this.imagePage = {};
  }

  @Action
  [Actions.GET_PAGES](pageIndex = 1) {
    return ApiService.query(`/admin/staticPages`, {
      params: { page: pageIndex, pageSize: 30 },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PAGES, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }
  @Action
  [Actions.ADD_PAGE](payload) {
    return ApiService.post(`/admin/staticPage`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.EDIT_PAGE](payload) {
    return ApiService.put(`/admin/staticPage/${payload.id}`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.GET_PAGE_SELECT]() {
    return ApiService.get(`/admin/staticPage/select`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PAGE_SELECT, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.GET_PAGE_INFO](payload) {
    return ApiService.get(`/admin/staticPage/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PAGE_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_IMAGE_PAGE](payload) {
    return ApiService.get(`/admin/staticPage/image/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IMAGE_PAGE, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.EDIT_IMAGE_PAGE](payload) {
    return ApiService.patch(
      `/admin/staticPage/image/${payload.id}`,
      payload.data
    )
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.DELETE_PAGE](payload) {
    return ApiService.delete(`/admin/staticPage/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
}
