export class State {
  travels = {};
  travelInfo = {};
  travelDetails = {};
  travelTypes = {};
  travelSEO = {};
  travelTags = {};
  travelTagsSearch = {};
  travelReports = {};
}
