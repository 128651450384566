import moment from "moment";

export function groupBy(array, key) {
  const result = {};
  if (array.length > 0) {
    array.forEach((item) => {
      if (!result[item[key]]) {
        result[item[key]] = [];
      }
      result[item[key]].push(item);
    });
  }

  return result;
}
export function formatPermission(permission) {
  const lastIndex = permission.lastIndexOf(".");
  return permission.substring(lastIndex + 1);
}
export function getPermission(array) {
  const result = [] as Array<string>;
  Object.keys(array).forEach((key) => {
    array[key].forEach((item) => {
      if (item.selected) {
        result.push(item.value);
      }
    });
  });
  return result;
}
export function getDiffTwoArray(a: string[], b: string[]) {
  return b.filter((e) => !a.includes(e));
}

export function exists(item: string, array: string[]): boolean {
  if (array) {
    return array.includes(item);
  }
  return false;
}
export function sanitizeTitle(title) {
  let slug = "";
  // Change to lower case
  const titleLower = title.toLowerCase();
  // Letter "e"
  slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
  // Letter "a"
  slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
  // Letter "o"
  slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
  // Letter "u"
  slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
  slug = slug.replace(/y|ý|ỷ|ỹ|ỵ|ỳ/gi, "y");
  slug = slug.replace(/i|í|ỉ|ĩ|ị|ì/gi, "i");
  // Letter "d"
  slug = slug.replace(/đ/gi, "d");
  // Trim the last whitespace
  // slug = slug.replace(/\s*$/g, "");
  // // Change whitespace to "-"
  // slug = slug.replace(/\s+/g, "-");
  return slug
    .replace(/^-+/g, "") // remove one or more dash at the start of the string
    .replace(/[^\w-]+/g, "-") // convert any on-alphanumeric character to a dash
    .replace(/-+/g, "-") // convert consecutive dashes to singuar one
    .replace(/-+$/g, "");
}
export function convertPosition(data) {
  data.lat = parseFloat(data.lat) || 0;
  data.lng = parseFloat(data.lng) || 0;
  if (!isEmpty(data.position)) {
    const result = [{}];
    result.shift();
    const coordinates = JSON.parse(data.position)[0].split(",");
    for (let index = 0; index < coordinates.length; index++) {
      const lat = parseFloat(coordinates[index].split(" ")[0]) || 0;
      const lng = parseFloat(coordinates[index].split(" ")[1]) || 0;
      if (lat != 0 && lng != 0) {
        result.push({
          lat: lat,
          lng: lng,
        });
      }
    }
    data.position = result;
    return data;
  }
  data.position = [];
  return data;
}
function isEmpty(str) {
  return !str || str.length === 0;
}
export function ishasOwnProperty(object: any, key: string): boolean {
  return Object.prototype.hasOwnProperty.call(object, key);
}
export function formatCash(str) {
  if (isEmpty(str)) {
    return str;
  }
  return str
    .toString()
    .split("")
    .reverse()
    .reduce((prev, next, index) => {
      return (index % 3 ? next : next + ",") + prev;
    });
}
export function isObject(obj) {
  return obj != null && obj.constructor.name === "Object";
}
export function convertEstateInfo(estateInfo) {
  try {
    if (
      estateInfo.startTime != "Invalid date" &&
      estateInfo.endTime != "Invalid date"
    ) {
      estateInfo.endTime = moment(estateInfo.endTime).format("YYYY-MM-DD");
      estateInfo.startTime = moment(estateInfo.startTime).format("YYYY-MM-DD");
    }

    estateInfo.imageUploads = [];
    estateInfo.videoUploads = [];
    estateInfo.vrUploads = [];
    estateInfo.images?.forEach((element) => {
      estateInfo.imageUploads.push({
        name: element.name,
        preview: element.src,
        file: null,
      });
    });
    estateInfo.vrs?.forEach((element) => {
      estateInfo.vrUploads.push({
        name: element.name,
        preview: element.src,
        file: null,
      });
    });
    estateInfo.videos?.forEach((element) => {
      estateInfo.videoUploads.push({
        name: element.name,
        preview: element.src,
        file: null,
      });
    });
    return estateInfo;
  } catch (error) {
    console.log(error);
  }
}
// If user tries to upload videos other than these extension , it will throw error.
export function isVideo(filename) {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mp4":
    case "mov":
    case "mpg":
    case "mpeg":
      // etc
      return true;
  }
  return false;
}

export function getExtension(filename) {
  const parts = filename.split(".");
  return parts[parts.length - 1];
}
