import { IUserManualQuery, UserManualOrder } from "@/interfaces/IUserManual";

interface Article {
  userManualQuery: IUserManualQuery;
}

export class State implements Article {
  userManualQuery = {
    page: 1,
    pageSize: 30,
    order: UserManualOrder.DESC,
    published: null as any,
    name: "",
  };
}
