import {
  IUISection,
  UISectionInfo,
  UISectionSelect,
} from "@/interfaces/IUISection";
export class State {
  uiSections = {} as IUISection;
  uiSectionInfo = {} as UISectionInfo;
  uiSetionPage = {} as UISectionSelect[];
  uiSectionOfPage = {} as UISectionSelect[];
  uiSectionImage = {};
  uiSectionSort = {};
}
