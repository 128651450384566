import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

declare global {
  interface Navigator {
    msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export default <ActionTree<State, any>>{
  [Actions.GET_REPORT_ACCOUNT](store, payload) {
    return ApiService.query(`/admin/report/account`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_ACCOUNT, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_REPORT_POSTS](store, payload) {
    return ApiService.query(`/admin/report/posts`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_POSTS, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_REPORT_POSTSVIP](store, payload) {
    return ApiService.query(`/admin/report/postsVip`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_POSTSVIP, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },

  [Actions.GET_REPORT_TRAFFIC](store, payload) {
    return ApiService.query(`/admin/report/new/traffic`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_TRAFFIC, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_REPORT_ORDER](store, payload) {
    return ApiService.query(`/admin/report/new/order`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_ORDER, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_REPORT_PRODUCT](store, payload) {
    return ApiService.query(`/admin/report/new/product`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_PRODUCT, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_REPORT_SELLER](store, payload) {
    return ApiService.query(`/admin/report/new/seller`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_SELLER, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_REPORT_TRANSACTION](store, payload) {
    return ApiService.query(`/admin/report/new/transaction`, {
      params: payload,
    })
      .then(({ data }) => {
        store.commit(Mutations.SET_REPORT_TRANSACTION, data.resultObj);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.GET_EXPORT_NEW](store, payload) {
    return ApiService.query(`/admin/report/new/export`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_EXPORT_NEW, data);
        const arrayBuffer = data;
        const blob = new Blob([arrayBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const filename = "filetest.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // For Internet Explorer
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const blobURL = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobURL;
          link.download = filename;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobURL);
        }
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
  [Actions.UPDATE_TRAFFIC](store, payload) {
    return ApiService.post(`/admin/setting/updateTraffic`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },
};
