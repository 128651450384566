import ApiService from "@/core/services/ApiService";
import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
import { convertEstateInfo } from "@/core/helpers/extension";
export default <ActionTree<State, any>>{
  [Actions.GET_NOTIFICATIONS](store, payload) {
    return ApiService.query(`/admin/notifications`, { params: payload })
      .then(({ data }) => {
        store.commit(Mutations.SET_NOTIFICATIONS, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.GET_NOTIFICATION_INFO](store, payload) {
    return ApiService.get(`/admin/notification/${payload}`)
      .then(({ data }) => {
        store.commit(Mutations.SET_NOTIFICATION_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  [Actions.ADD_NOTIFICATION](store, payload) {
    return ApiService.post(`/admin/notification`, payload)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.validationErrors);
      });
  },

  [Actions.DELETE_NOTIFICATION](store, payload) {
    return ApiService.delete(`/admin/notification/${payload}`)
      .then(() => {
        store.commit(Mutations.RESET_ERROR);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
};
