import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";
import { IPosition } from "@/interfaces/IPosition";

export default <MutationTree<State>>{
  [Mutations.SET_POSITION](state, position) {
    state.position = position;
  },
  [Mutations.RESET_POSITION](state) {
    state.position = {} as IPosition;
  },
};
