import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ICountry, CountrySelectModel } from "@/interfaces/ICountry";

@Module
export default class CountryModule extends VuexModule {
  countrys = {} as ICountry[];
  countryInfo = {} as ICountry;
  countrySelect = {} as CountrySelectModel[];
  /**
   * Get countrys
   * @returns ICountry[]
   */
  get currentCountrys(): ICountry[] {
    return this.countrys;
  }
  /**
   * Get country Select
   * @returns CountrySelectModel[]
   */
  get getCountrySeleted(): CountrySelectModel[] {
    return this.countrySelect;
  }
  /**
   * Get country Info
   * @returns ICountry
   */
  get currentCountryInfo(): ICountry {
    return this.countryInfo;
  }

  @Mutation
  [Mutations.SET_COUNTRYS](payload): void {
    this.countrys = payload;
  }
  @Mutation
  [Mutations.SET_COUNTRY_INFO](payload): void {
    this.countryInfo = payload;
  }
  @Mutation
  [Mutations.SET_COUNTRY_SELECT](payload): void {
    this.countrySelect = payload;
  }
  @Mutation
  [Mutations.RESET_COUNTRY_INFO](): void {
    this.countrys = {} as ICountry[];
    this.countryInfo = {} as ICountry;
    this.countrySelect = {} as CountrySelectModel[];
  }

  @Action
  [Actions.GET_COUNTRYS]() {
    return ApiService.get(`/admin/countrys`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COUNTRYS, data.resultObj);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }
  @Action
  [Actions.GET_COUNTRY_INFO](payload) {
    return ApiService.get(`/admin/country/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COUNTRY_INFO, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.GET_COUNTRY_SELECT]() {
    return ApiService.get(`/admin/country/select`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COUNTRY_SELECT, data.resultObj);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.ADD_COUNTRY](payload) {
    return ApiService.post(`/admin/country`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
        //this.context.commit(Mutations.RESET_COUNTRY_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }

  @Action
  [Actions.EDIT_COUNTRY](payload) {
    return ApiService.put(`/admin/country/${payload.id}`, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
        //this.context.commit(Mutations.RESET_COUNTRY_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
  @Action
  [Actions.DELETE_COUNTRY](payload) {
    return ApiService.delete(`/admin/country/${payload}`)
      .then(() => {
        this.context.commit(Mutations.RESET_ERROR);
        this.context.commit(Mutations.RESET_COUNTRY_INFO);
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data.validationErrors
        );
      });
  }
}
