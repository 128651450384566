import { MutationTree } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { State } from "./state";

export default <MutationTree<State>>{
  [Mutations.SET_REPORT_POSTS](state, payload) {
    state.reportPosts = payload;
  },
  [Mutations.SET_REPORT_POSTSVIP](state, payload) {
    state.reportPostVip = payload;
  },
  [Mutations.SET_REPORT_ACCOUNT](state, payload) {
    state.reportAccount = payload;
  },
  [Mutations.SET_REPORT_POSTS_INFO](state, payload) {
    state.reportPostsInfo = payload;
  },
  [Mutations.SET_REPORT_POSTSVIP_INFO](state, payload) {
    state.reportPostsVipInfo = payload;
  },

  [Mutations.SET_REPORT_TRAFFIC](state, payload) {
    state.reportTraffic = payload;
  },
  [Mutations.SET_REPORT_ORDER](state, payload) {
    state.reportOrder = payload;
  },
  [Mutations.SET_REPORT_PRODUCT](state, payload) {
    state.reportProduct = payload;
  },
  [Mutations.SET_REPORT_SELLER](state, payload) {
    state.reportSeller = payload;
  },
  [Mutations.SET_REPORT_TRANSACTION](state, payload) {
    state.reportTransaction = payload;
  },
  [Mutations.SET_EXPORT_NEW](state, payload) {
    state.reportExcel = payload;
  },
};
